import {
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  Spinner,
  SplitPanel,
} from '@cloudscape-design/components';
import { splitPanelI18nStrings } from 'src/i18n/split-panel';

import { createCloudConnectionAction, useGetCloudConnection } from 'api/admin';

import CopyText from 'components/copy-text';

import { ValueWithLabel } from 'components/value-with-label';
import { ActionType, CloudType } from 'api/admin-models';
import { ActionButton } from 'pages/upgrade_plans/helpers';

export function CloudConnectionDetails({
  cloudConnection: item,
}: {
  cloudConnection: { accountId: string; cloudConnectionId: string };
}) {
  const { data, mutate } = useGetCloudConnection(
    item.accountId,
    item.cloudConnectionId,
  );

  if (data === undefined) {
    return (
      <SplitPanel
        header={`Cloud Connection: ${item.cloudConnectionId}`}
        i18nStrings={splitPanelI18nStrings}
      >
        <Spinner />
      </SplitPanel>
    );
  }

  return (
    <SplitPanel
      header={`Cloud Connection: ${data.cloud_type} ${
        data.aws?.aws_account_id || data.gcp?.project_id
      }`}
      i18nStrings={splitPanelI18nStrings}
    >
      <SpaceBetween size="l">
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <ActionButton
                    key="verify"
                    onConfirm={async () => {
                      await createCloudConnectionAction(
                        item.accountId,
                        item.cloudConnectionId,
                        {
                          action_type: ActionType.VERIFY,
                        },
                      );
                      await mutate();
                    }}
                  >
                    Verify
                  </ActionButton>
                </SpaceBetween>
              }
            >
              Details
            </Header>
          }
        >
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label="Account ID">
                <CopyText
                  copyText={item.accountId}
                  copyButtonLabel="Copy Account ID"
                  successText="Account ID copied"
                  errorText="Account ID failed to copy"
                />
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Cloud Connection ID">
                <CopyText
                  copyText={data.id}
                  copyButtonLabel="Copy Cloud Connection ID"
                  successText="Cloud Connection ID copied"
                  errorText="Cloud Connection ID failed to copy"
                />
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Cloud">
                {data?.cloud_type || 'n/a'}
              </ValueWithLabel>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
        {data?.cloud_type === CloudType.AWS && (
          <Container header={<></>}>
            <ColumnLayout columns={3} variant="text-grid">
              <SpaceBetween size="l">
                <ValueWithLabel label="AWS Account ID">
                  <CopyText
                    copyText={data.aws?.aws_account_id || ''}
                    copyButtonLabel="Copy AWS Account ID"
                    successText="AWS Account ID copied"
                    errorText="AWS Account ID failed to copy"
                  />
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ValueWithLabel label="Chkk IAM Role">
                  {data.aws?.iam.chkk_role_name || 'n/a'}
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ValueWithLabel label="External ID">
                  {data.aws?.iam.external_id || 'n/a'}
                </ValueWithLabel>
              </SpaceBetween>
            </ColumnLayout>
          </Container>
        )}
        {data?.cloud_type === CloudType.GCP && (
          <Container header={<></>}>
            <ColumnLayout columns={3} variant="text-grid">
              <SpaceBetween size="l">
                <ValueWithLabel label="GCP Project ID">
                  <CopyText
                    copyText={data.gcp?.project_id || ''}
                    copyButtonLabel="Copy GCP Project ID"
                    successText="GCP Project ID copied"
                    errorText="GCP Project ID failed to copy"
                  />
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                <ValueWithLabel label="Service Account Email">
                  {data.gcp?.iam.chkk_service_account_email || 'n/a'}
                </ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l"></SpaceBetween>
            </ColumnLayout>
          </Container>
        )}
      </SpaceBetween>
    </SplitPanel>
  );
}
