import { useCallback, useState } from 'react';
import { FlashbarProps } from '@cloudscape-design/components';
import { useGetId } from 'react-use-id-hook';

export default function useNotifications() {
  const idGenerator = useGetId();
  const [notifications, setNotifications] = useState<
    Array<FlashbarProps.MessageDefinition>
  >([]);

  const dismissNotification = useCallback((id: string) => {
    setNotifications((notifications) =>
      notifications.filter((notification) => notification.id !== id),
    );
  }, []);

  const addNotification = useCallback(
    (header: string, message: string, type: FlashbarProps.Type) => {
      setNotifications((notifications) => {
        const id = idGenerator();
        return [
          {
            id: id,
            type: type,
            dismissible: true,
            statusIconAriaLabel: 'error',
            dismissLabel: 'Dismiss message',
            header: header,
            content: message,
            onDismiss: () => dismissNotification(id),
          },
          ...notifications,
        ];
      });
    },
    [dismissNotification],
  );

  return {
    notifications,
    addNotification,
  };
}
