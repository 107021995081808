import {
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  Spinner,
  SplitPanel,
} from '@cloudscape-design/components';
import { useGetTable } from 'api/analytics';
import { TableColumn } from 'api/analytics-models';
import { FilterableTable } from 'components/tables/filterable-table';
import { ValueWithLabel } from 'components/value-with-label';
import { splitPanelI18nStrings } from 'i18n/split-panel';

export function MidasTableDetails({ tableName }: { tableName: string }) {
  const { data } = useGetTable(tableName);

  if (data === undefined) {
    return (
      <SplitPanel
        header={`Table: ${tableName}`}
        i18nStrings={splitPanelI18nStrings}
      >
        <Spinner /> Still Loading
      </SplitPanel>
    );
  }
  return (
    <SplitPanel
      header={`Table: ${tableName}`}
      i18nStrings={splitPanelI18nStrings}
    >
      <SpaceBetween size="l">
        <Container header={<Header variant="h2">Table details</Header>}>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label="Table Name">{data.name}</ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Table Description">
                {data.description}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l"></SpaceBetween>
          </ColumnLayout>
        </Container>
        <FilterableTable<TableColumn>
          data={data.columns}
          header={<Header variant="h2">Fields</Header>}
          variant={'container'}
          columns={[
            {
              id: 'name',
              label: 'Name',
              cell: (item) => item.name,
              defaultVisible: true,
              sortingField: 'name',
            },
            {
              id: 'type',
              label: 'Type',
              cell: (item) => item.type,
              defaultVisible: true,
              sortingField: 'type',
            },
          ]}
          selectionMode="none"
          selectionTrackBy={(item) => item.name}
        />
      </SpaceBetween>
    </SplitPanel>
  );
}
