import { FilterableTable } from 'components/tables/filterable-table';
import { format } from 'date-fns';
import { useListIntegrations } from 'api/admin';

export function AccountIntegrations(props: {
  orgId: string;
  accountId: string;
}): JSX.Element {
  const { data: integrationsData } = useListIntegrations(props.accountId);

  return (
    <FilterableTable
      selectionMode="none"
      data={integrationsData?.data}
      columns={[
        {
          id: 'id',
          label: 'ID',
          cell: (item) => item.integration_id || '-',

          defaultVisible: false,

          sortingField: 'id',
          filterOperators: ['='],
        },
        {
          id: 'integration_type',
          label: 'Type',
          cell: (item) => item.integration_type || '-',

          defaultVisible: true,

          sortingField: 'integration_type',
          filterOperators: ['=', '!=', ':', '!:'],
        },
        {
          id: 'integration_name',
          label: 'Name',
          cell: (item) => item.integration_name || '-',

          defaultVisible: true,

          sortingField: 'integration_name',
          filterOperators: ['=', '!=', ':', '!:'],
        },
        {
          id: 'status',
          label: 'Status',
          cell: (item) => item.status || '-',

          defaultVisible: true,

          sortingField: 'status',
          filterOperators: ['=', '!=', ':', '!:'],
        },
        {
          id: 'created',
          label: 'Created',
          cell: (item) => format(new Date(item.created * 1000), 'Pp'),

          defaultVisible: true,

          sortingField: 'created',
          filterOperators: ['=', '!=', ':', '!:'],
        },
        {
          id: 'updated',
          label: 'Last Updated',
          cell: (item) => format(new Date((item.updated || 0) * 1000), 'Pp'),

          defaultVisible: true,

          sortingField: 'updated',
          filterOperators: ['=', '!=', ':', '!:'],
        },
      ]}
      selectionTrackBy={(item) => item.integration_id}
    />
  );
}
