import * as React from 'react';
import Table from '@cloudscape-design/components/table';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import { UpgradeContentBlockInfoBoxItem } from 'api/admin-models';
import { ButtonDropdown, Input } from '@cloudscape-design/components';

export interface HeaderItemsTableProps {
  editable: boolean;
  initialItems: UpgradeContentBlockInfoBoxItem[];
  onSave: (items: UpgradeContentBlockInfoBoxItem[]) => Promise<void>;
}

export default function HeaderItemsTable(
  props: HeaderItemsTableProps,
): JSX.Element {
  const [items, setItems] = React.useState<UpgradeContentBlockInfoBoxItem[]>(
    props.initialItems,
  );
  const [selectedItem, setSelectedItem] = React.useState<
    UpgradeContentBlockInfoBoxItem | undefined
  >(undefined);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => setItems(props.initialItems), [props.initialItems]);

  return (
    <Table
      selectionType={'single'}
      onSelectionChange={(event) =>
        setSelectedItem(event.detail.selectedItems[0])
      }
      selectedItems={selectedItem === undefined ? undefined : [selectedItem]}
      columnDefinitions={[
        {
          id: 'title',
          header: 'Title',
          cell: (item) => item.title || '-',
          sortingField: 'title',
          editConfig: {
            ariaLabel: 'Title',
            editIconAriaLabel: 'editable',
            errorIconAriaLabel: 'Title Error',
            editingCell: (item, { currentValue, setValue }) => {
              return (
                <Input
                  autoFocus={true}
                  value={(currentValue as string | undefined) ?? item.title}
                  onChange={(event) => setValue(event.detail.value)}
                />
              );
            },
          },
        },
        {
          id: 'value',
          header: 'Value',
          cell: (item) => item.value || '-',
          sortingField: 'value',
          editConfig: {
            ariaLabel: 'value',
            editIconAriaLabel: 'editable',
            errorIconAriaLabel: 'Value Error',
            editingCell: (item, { currentValue, setValue }) => {
              return (
                <Input
                  autoFocus={true}
                  value={(currentValue as string | undefined) ?? item.value}
                  onChange={(event) => setValue(event.detail.value)}
                />
              );
            },
          },
        },
        {
          id: 'icon',
          header: 'Icon',
          cell: (item) => item.icon || '-',
          sortingField: 'icon',
          editConfig: {
            ariaLabel: 'value',
            editIconAriaLabel: 'editable',
            errorIconAriaLabel: 'Value Error',
            editingCell: (item, { currentValue, setValue }) => {
              return (
                <Input
                  autoFocus={true}
                  value={
                    (currentValue as string | undefined) ?? (item.icon || '')
                  }
                  onChange={(event) => setValue(event.detail.value)}
                />
              );
            },
          },
        },
      ]}
      items={items}
      loadingText="Loading resources"
      sortingDisabled
      submitEdit={async (item, column, newValue) => {
        if (column.id === 'title') {
          item.title = newValue as string;
        } else if (column.id === 'value') {
          item.value = newValue as string;
        } else if (column.id === 'icon') {
          if (newValue !== '') {
            item.icon = newValue as string;
          } else {
            item.icon = undefined;
          }
        }
        await new Promise((e) => setTimeout(e, 1e3));
      }}
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <ButtonDropdown
                loading={saving}
                items={[
                  { text: 'Delete', id: 'rm', disabled: false },
                  { text: 'Move Up', id: 'up', disabled: false },
                  { text: 'Move Down', id: 'down', disabled: false },
                ]}
                onItemClick={(event) => {
                  switch (event.detail.id) {
                    case 'rm': {
                      setItems((prev) =>
                        prev.filter((i) => i !== selectedItem),
                      );
                      setSelectedItem(undefined);
                      break;
                    }
                    case 'up': {
                      setItems((prev) => {
                        const idx = prev.findIndex((i) => i === selectedItem);
                        if (idx === 0) {
                          return prev;
                        } else {
                          return [
                            ...prev.slice(0, idx - 1),
                            prev[idx],
                            prev[idx - 1],
                            ...prev.slice(idx + 1),
                          ];
                        }
                      });
                      break;
                    }
                    case 'down': {
                      setItems((prev) => {
                        const idx = prev.findIndex((i) => i === selectedItem);
                        if (idx === prev.length - 1) {
                          return prev;
                        } else {
                          return [
                            ...prev.slice(0, idx),
                            prev[idx + 1],
                            prev[idx],
                            ...prev.slice(idx + 2),
                          ];
                        }
                      });
                      break;
                    }
                  }
                }}
              >
                Actions
              </ButtonDropdown>
              <Button
                loading={saving}
                onClick={() =>
                  void (async () => {
                    setSaving(true);
                    try {
                      await props.onSave(items);
                    } finally {
                      setSaving(false);
                    }
                  })()
                }
              >
                Save
              </Button>
              <Button
                loading={saving}
                variant="primary"
                onClick={() => {
                  setItems((prev) => [
                    ...prev,
                    { title: 'New Title', value: '42' },
                  ]);
                }}
              >
                Add item
              </Button>
            </SpaceBetween>
          }
        >
          Heading Info Bar
        </Header>
      }
      empty={
        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No Items</b>
          </SpaceBetween>
        </Box>
      }
    />
  );
}
