import BreadcrumbGroup from '@cloudscape-design/components/breadcrumb-group';
import { Params, useMatches, useNavigate } from 'react-router';

interface RouteHandle {
  BreadcrumbLabel: (params: Params<string>) => string;
}

export function Breadcrumbs() {
  const navigate = useNavigate();

  const matches = useMatches();
  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => match.handle instanceof Object)
    .filter((match) => 'BreadcrumbLabel' in (match.handle as object))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => {
      return {
        text: (match.handle as RouteHandle).BreadcrumbLabel(match.params),
        href: match.pathname,
      };
    });

  return (
    <BreadcrumbGroup
      items={[{ text: 'Home', href: '/' }, ...crumbs]}
      expandAriaLabel="Show path"
      ariaLabel="Breadcrumbs"
      onFollow={(event) => {
        navigate(event.detail.href);
        event.preventDefault();
      }}
    />
  );
}
