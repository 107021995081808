import * as React from 'react';
import Table, { TableProps } from '@cloudscape-design/components/table';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import { ButtonDropdown, Input } from '@cloudscape-design/components';
import { QueryVariable } from 'api/analytics-models';

export interface QueryVariablesTableProps {
  editable: 'none' | 'all' | 'local-value';
  loading: boolean;

  title?: string;
  variant?: TableProps.Variant;

  items: QueryVariable[];
  setItems: (func: (items: QueryVariable[]) => QueryVariable[]) => void;
}

export default function QueryVariablesTable(
  props: QueryVariablesTableProps,
): JSX.Element {
  const [selectedItem, setSelectedItem] = React.useState<
    QueryVariable | undefined
  >(undefined);

  return (
    <Table
      variant={props.variant}
      selectionType={props.editable === 'all' ? 'single' : undefined}
      onSelectionChange={(event) =>
        setSelectedItem(event.detail.selectedItems[0])
      }
      selectedItems={selectedItem === undefined ? undefined : [selectedItem]}
      columnDefinitions={[
        {
          id: 'name',
          header: 'Name',
          cell: (item) => item.name || '-',
          sortingField: 'name',
          editConfig:
            props.editable === 'all'
              ? {
                  ariaLabel: 'Name',
                  editIconAriaLabel: 'editable',
                  errorIconAriaLabel: 'Name Error',
                  validation: (_, value) => {
                    if (value === '') {
                      return 'Name is required';
                    }
                    if (!/^[a-zA-Z0-9_]*$/.test(value as string)) {
                      return 'Name can only contain letters, numbers and underscores';
                    }

                    return undefined;
                  },
                  editingCell: (item, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={
                          (currentValue as string | undefined) ?? item.name
                        }
                        onChange={(event) => setValue(event.detail.value)}
                      />
                    );
                  },
                }
              : undefined,
        },
        {
          id: 'description',
          header: 'Description',
          cell: (item) => item.description || '-',
          sortingField: 'description',
          editConfig:
            props.editable === 'all'
              ? {
                  ariaLabel: 'description',
                  editIconAriaLabel: 'editable',
                  errorIconAriaLabel: 'Description Error',
                  editingCell: (item, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={
                          (currentValue as string | undefined) ??
                          (item.description || '')
                        }
                        onChange={(event) => setValue(event.detail.value)}
                      />
                    );
                  },
                }
              : undefined,
        },
        {
          id: 'localValue',
          header: 'Value',
          cell: (item) => item.localValue || '-',
          sortingField: 'localValue',
          editConfig:
            props.editable === 'all' || props.editable === 'local-value'
              ? {
                  ariaLabel: 'localValue',
                  editIconAriaLabel: 'editable',
                  errorIconAriaLabel: 'Local Value Error',
                  editingCell: (item, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={
                          (currentValue as string | undefined) ??
                          (item.localValue || '')
                        }
                        onChange={(event) => setValue(event.detail.value)}
                      />
                    );
                  },
                }
              : undefined,
        },
      ]}
      items={props.items}
      loadingText="Loading resources"
      sortingDisabled
      submitEdit={async (item, column, newValue) => {
        if (column.id === 'name') {
          item.name = newValue as string;
        } else if (column.id === 'description') {
          item.description = newValue as string;
        } else if (column.id === 'localValue') {
          item.localValue = newValue as string;
        }
        await new Promise((e) => setTimeout(e, 1e3));
      }}
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              {props.editable === 'all' && (
                <ButtonDropdown
                  loading={props.loading}
                  items={[{ text: 'Delete', id: 'rm', disabled: false }]}
                  onItemClick={(event) => {
                    switch (event.detail.id) {
                      case 'rm': {
                        props.setItems((prev) =>
                          prev.filter((i) => i !== selectedItem),
                        );
                        setSelectedItem(undefined);
                        break;
                      }
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
              )}
              {props.editable === 'all' && (
                <Button
                  loading={props.loading}
                  variant="primary"
                  onClick={() => {
                    props.setItems((prev) => [
                      ...prev,
                      { name: 'NewVariable' },
                    ]);
                  }}
                >
                  Add item
                </Button>
              )}
            </SpaceBetween>
          }
        >
          {props.title || ''}
        </Header>
      }
      empty={
        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No Items</b>
          </SpaceBetween>
        </Box>
      }
    />
  );
}
