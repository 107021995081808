import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  SpaceBetween,
} from '@cloudscape-design/components';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { createQuery, updateQuery } from 'api/analytics';
import { Query } from 'api/analytics-models';

export default function CreateQueryModal(props: {
  open: boolean;
  onDismiss: () => void;
  onSubmit: () => void;

  mode: 'create' | 'edit';
  query?: Query;
}) {
  const createQuerySchema = Yup.object().shape({
    name: Yup.string().max(100).required(),
    description: Yup.string().max(1000).min(0).required(),
  });

  const defaultValues = {
    name: props.mode === 'edit' ? props.query?.name || '' : '',
    description: props.mode === 'edit' ? props.query?.description || '' : '',
  };

  const methods = useForm({
    resolver: yupResolver(createQuerySchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    reset,
    clearErrors,
  } = methods;

  const onSubmit = async (data: { name: string; description: string }) => {
    if (props.mode === 'create') {
      await createQuery({
        name: data.name,
        description: data.description,
        variables: [],
        query: '',
        language: 'SQL',
      });
    } else if (props.mode === 'edit') {
      await updateQuery(props.query?.id || '', {
        name: data.name,
        description: data.description,
        variables: [],
      });
    }
    props.onSubmit();
  };

  useEffect(() => {
    reset(defaultValues);
    clearErrors();
  }, [props.open]);

  return (
    <Modal
      onDismiss={props.onDismiss}
      visible={props.open}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={props.onDismiss}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              form="create-query-form"
              formAction="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Create Query"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} id="create-query-form">
          <SpaceBetween size="m">
            <FormField label="Name" errorText={errors.name?.message}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    value={field.value}
                    onChange={(event) =>
                      field.onChange({ target: { value: event.detail.value } })
                    }
                    onBlur={field.onBlur}
                  />
                )}
              />
            </FormField>
            <FormField
              label="Description"
              errorText={errors.description?.message}
            >
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Input
                    value={field.value}
                    onChange={(event) =>
                      field.onChange({ target: { value: event.detail.value } })
                    }
                    onBlur={field.onBlur}
                  />
                )}
              />
            </FormField>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}
