import { PropertyFilterToken } from '@cloudscape-design/collection-hooks';
import { StatusIndicator } from '@cloudscape-design/components';
import { useListClusters } from 'api/admin';
import { Cluster, ClusterStatus } from 'api/admin-models';
import { FilterableTable } from 'components/tables/filterable-table';
import { format } from 'date-fns';
import { useSplitPanel } from 'contexts/split-panel';
import { ClusterDetails } from './clusters_details';

export function ClusterBadgeStatus({
  value,
}: {
  value: ClusterStatus;
}): JSX.Element {
  if (value === 'active') {
    return <StatusIndicator type="success">{value}</StatusIndicator>;
  } else if (value === 'deactivated') {
    return <StatusIndicator type="info">{value}</StatusIndicator>;
  } else {
    return <> {value} </>;
  }
}
export function AccountClusters(props: {
  orgId: string;
  accountId: string;
}): JSX.Element {
  const updateSplitPanel = useSplitPanel();
  const { data } = useListClusters(props.accountId);

  return (
    <FilterableTable<Cluster>
      selectionMode="single"
      data={data ? data.data : undefined}
      columns={[
        {
          id: 'AccountId',
          label: 'Account ID',
          cell: (item) => item.account_id,

          defaultVisible: false,

          sortingField: 'account_id',
        },
        {
          id: 'ClusterId',
          label: 'Cluster ID',
          cell: (item) => item.id,

          defaultVisible: false,
          sortingField: 'id',
          filterOperators: ['=', '!='],
        },
        {
          id: 'Name',
          label: 'Name',
          cell: (item) => item.name || '-',

          defaultVisible: true,

          sortingField: 'name',
          filterOperators: ['=', '!=', ':', '!:'],
        },
        {
          id: 'Status',
          label: 'Status',
          cell: (item) => (
            <ClusterBadgeStatus value={item.status || 'active'} />
          ),

          defaultVisible: true,

          sortingField: 'status',
          filterOperators: ['=', '!='],
          filterOptions: [
            'pending',
            'in-progress',
            'completed',
            'rolled-back',
            'failed',
          ],
        },
        {
          id: 'k8sVersion',
          label: 'K8s Version',
          cell: (item) => item.version,
          defaultVisible: true,

          sortingField: 'version',
          filterOperators: ['=', '!=', ':', '!:'],
        },
        {
          id: 'provider',
          label: 'Provider',
          cell: (item) => item.cloud_provider,
          defaultVisible: true,

          sortingField: 'cloud_provider',
          filterOperators: ['=', '!='],
        },
        {
          id: 'NodeCount',
          label: 'Node Count',
          cell: (item) => item.node_count,
          defaultVisible: true,

          sortingField: 'node_count',
          filterOperators: ['=', '!=', '<', '>', '<=', '>='],
        },
        {
          id: 'ChkkVersion',
          label: 'Chkk Version',
          cell: (item) => item.chkk_metadata.version,
          defaultVisible: true,

          sortingField: 'chkk_version',
          filterOperators: ['=', '!=', ':', '!:'],
        },
        {
          id: 'Creation',
          label: 'Created',
          cell: (item) => format(new Date(item.created * 1000), 'Pp'),

          defaultVisible: true,

          sortingField: 'created',
        },
      ]}
      defaultFilterQuery={(searchParams) => {
        const tokens: PropertyFilterToken[] = [];

        if (searchParams.get('status') === 'pending') {
          tokens.push({
            propertyKey: 'Status',
            value: searchParams.get('status'),
            operator: '=',
          });
        }
        return Promise.resolve({ tokens: tokens, operation: 'and' });
      }}
      selectionTrackBy={(item) => `${item.account_id}#${item.id}`}
      onSelectionChange={(event) => {
        updateSplitPanel(
          <ClusterDetails
            cluster={{
              accountId: props.accountId,
              clusterId: event.detail.selectedItems[0].id,
            }}
          />,
        );
      }}
    />
  );
}
