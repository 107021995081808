import { UpgradeStage } from 'api/admin-models';

export const EMPTY_PLAN_STAGES: UpgradeStage[] = [
  // 0. Learn
  {
    stage_number: 0,
    name: 'Learn',
    status: 'active',
    allowed_action_types: [],
    created: 42,
    updated: 42,

    footer_content_blocks: [],

    steps: [],
  },
];
