import { UpgradeStage } from 'api/admin-models';

export const EKS_124_125_INPLACE_PLAN_STAGES: UpgradeStage[] = [
  {
    stage_number: 1,
    name: 'Review Recommendations',
    status: 'active',
    allowed_action_types: [],
    created: 42,
    updated: 42,
    header_content_blocks: [
      {
        type: 'info-section',
        items: [
          {
            title: 'Version',
            value: 'v1.24',
          },
          {
            title: 'Recommended',
            value: 'v1.25',
          },
          {
            title: 'Provider',
            value: 'Amazon EKS',
          },
          {
            title: 'Region',
            value: 'us-east-2',
          },
          {
            title: 'AddOns',
            value: '13',
          },
          {
            title: 'Applications',
            value: '15',
          },
          {
            title: 'Node Groups',
            value: '2',
          },
          {
            title: 'Upgrade Type',
            value: 'In-Place',
          },
        ],
      },
      {
        type: 'text',
        style: 'normal',
        text: "The Review Recommendations stage provides stakeholders with an overview of the downstream changes, reasons for those changes, and Chkk's recommendations.\n It highlights the impacted components such as Node Groups, Nodes, Addons, Applications, IAM Roles, and other resources.",
      },
    ],
    steps: [
      {
        step_number: 1,
        name: 'Resources Requiring Changes',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Addons',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Current Version',
              },
              {
                title: 'Recommended Version',
              },
              {
                title: 'Reasons to upgrade',
              },
            ],
            data: [
              [
                {
                  value: 'VPC CNI',
                },
                {
                  value: 'v1.11.0-eksbuild.1',
                },
                {
                  value: 'v1.12.6-eksbuild.2',
                },
                {
                  value:
                    'VPC CNI v1.12.6 is the officially recommended version for Amazon EKS v1.25',
                },
              ],
              [
                {
                  value: 'Kube Proxy',
                },
                {
                  value: 'v1.24.7-eksbuild.2',
                },
                {
                  value: 'v1.25.6-minimal-eksbuild.2',
                },
                {
                  value:
                    'KubeProxy v1.25.6 is the officially recommended version for Amazon EKS v1.25',
                },
              ],
              [
                {
                  value: 'Core DNS',
                },
                {
                  value: 'v1.8.7-eksbuild.3',
                },
                {
                  value: 'v1.9.3-eksbuild.3',
                },
                {
                  value:
                    'Core DNS v1.9.3 is the officially recommended version for Amazon EKS v1.25',
                },
              ],
              [
                {
                  value: 'AWS Load Balancer Controller',
                },
                {
                  value: 'v2.4.4',
                },
                {
                  value: 'v2.4.7',
                },
                {
                  value:
                    'v2.4.7 supports discovery.k8s.io/v1 version of EndpointSlice for compatibility with k8s 1.25 and later releases.',
                },
              ],
              [
                {
                  value: 'Cert Manager',
                },
                {
                  value: 'v1.9',
                },
                {
                  value: 'v1.11',
                },
                {
                  value:
                    'cert-manager v1.9 is not compatible with Kubernetes v1.25. You need to upgrade to cert-manager v1.10 or later. v1.10 reached the end of support on May 19, 2023. v1.11 is the currently supported version.',
                },
              ],
              [
                {
                  value: 'External DNS',
                },
                {
                  value: 'v0.10.1',
                },
                {
                  value: 'v0.13.4',
                },
                {
                  value:
                    'You’re running a 2-year-old version of External DNS. There have been various bug fixes and vulnerabilities since the v0.10.1 release. We recommend upgrading to v0.13.4.',
                },
              ],
              [
                {
                  value: 'EBS CSI Driver',
                },
                {
                  value: 'v1.5.2-eksbuild.1',
                },
                {
                  value: 'v1.19.0-eksbuild.1',
                },
                {
                  value:
                    'AWS EBS CSI Driver v1.19.0 is the officially recommended version for Amazon EKS v1.25. And you’re running a 1-year-old version of EBS CSI Driver. There have been various bug fixes and vulnerabilities since the v1.5.2 release. We recommend upgrading to v1.19.0.',
                },
              ],
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Applications',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Type of changes',
              },
              {
                title: 'Reasons for update',
              },
            ],
            data: [
              [
                {
                  value: 'api-worker',
                },
                {
                  value: 'API removal',
                },
                {
                  value:
                    'api-worker is using discovery.k8s.io/v1beta1 API, which is no longer served in v1.25. Upgrade to discovery.k8s.io/v1',
                },
              ],
              [
                {
                  value: 'nightly-verif',
                },
                {
                  value: 'API removal',
                },
                {
                  value:
                    'nightly-verif is using batch/v1beta1 API, which is no longer served in v1.25. Upgrade to batch/v1',
                },
              ],
              [
                {
                  value: 'asset-manager',
                },
                {
                  value: 'API removal',
                },
                {
                  value:
                    'assert-manager is using policy/v1beta1 API, which is no longer served in v1.25. Upgrade to policy/v1',
                },
              ],
              [
                {
                  value: 'fujin-simulator',
                },
                {
                  value: 'API removal',
                },
                {
                  value:
                    'fujin-simulator is using batch/v1beta1 API, which is no longer served in v1.25. Upgrade to batch/v1',
                },
              ],
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Node Groups',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Current AMI',
              },
              {
                title: 'Recommended AMI',
              },
              {
                title: 'Reasons to upgrade',
              },
            ],
            data: [
              [
                {
                  value: 'prod-1-critical',
                },
                {
                  value: '1.24.9-20230203',
                },
                {
                  value: '1.25.9-20230526',
                },
                {
                  value:
                    '1.25.x.y is the supported AMI version for Amazon EKS v1.25',
                },
              ],
              [
                {
                  value: 'prod-1-enhanced-network',
                },
                {
                  value: '1.24.9-20230203',
                },
                {
                  value: '1.25.9-20230526',
                },
                {
                  value:
                    '1.25.x.y is the supported AMI version for Amazon EKS v1.25',
                },
              ],
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'IAM Roles',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Type of changes',
              },
              {
                title: 'Reasons for change',
              },
            ],
            data: [
              [
                {
                  value: 'AmazonEKSLoadBalancerControllerRole',
                },
                {
                  value: 'Add Permissions',
                },
                {
                  value:
                    'AWS Load Balancer Controller has updated the reference IAM policies to explicitly add the AddTag permission for creating load balancer and listener resources.',
                },
              ],
            ],
          },
          {
            type: 'conversation',
          },
        ],
      },
      {
        step_number: 2,
        name: 'Unchanged Resources',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Addons',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Current Version',
              },
            ],
            data: [
              [
                {
                  value: 'Argo CD',
                },
                {
                  value: 'v2.6.7',
                },
              ],
              [
                {
                  value: 'Grafana',
                },
                {
                  value: 'v9.5.2',
                },
              ],
              [
                {
                  value: 'Prometheus',
                },
                {
                  value: 'v2.44.0',
                },
              ],
              [
                {
                  value: 'RabbitMQ',
                },
                {
                  value: 'v3.11.1',
                },
              ],
              [
                {
                  value: 'Node Problem Detector',
                },
                {
                  value: 'v0.8.13',
                },
              ],
              [
                {
                  value: 'Open Cost',
                },
                {
                  value: 'v1.99.0',
                },
              ],
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Applications',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Current Version',
              },
            ],
            data: [
              [
                {
                  value: 'api',
                },
                {
                  value: 'v0.11.2',
                },
              ],
              [
                {
                  value: 'cartwheel',
                },
                {
                  value: 'v1.30.0',
                },
              ],
              [
                {
                  value: 'catalog',
                },
                {
                  value: 'v2.1.4',
                },
              ],
              [
                {
                  value: 'checkout-manager',
                },
                {
                  value: 'v0.8.1',
                },
              ],
              [
                {
                  value: 'dashboard',
                },
                {
                  value: 'v2.3.0',
                },
              ],
              [
                {
                  value: 'frontend',
                },
                {
                  value: 'v1.4.2',
                },
              ],
              [
                {
                  value: 'order-processor',
                },
                {
                  value: 'v1.2.1',
                },
              ],
              [
                {
                  value: 'spam-detector',
                },
                {
                  value: 'v0.7.0',
                },
              ],
              [
                {
                  value: 'billing-manager',
                },
                {
                  value: 'v1.7.0',
                },
              ],
              [
                {
                  value: 'custom-authorizer',
                },
                {
                  value: 'v1.9.2',
                },
              ],
              [
                {
                  value: 'dionysus-daemon',
                },
                {
                  value: 'v0.2.1',
                },
              ],
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'IAM Roles',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
            ],
            data: [
              [
                {
                  value: 'AmazonEKSCSIRole-eks-prod-1',
                },
              ],
              [
                {
                  value: 'eks-prod-1-cluster-r',
                },
              ],
              [
                {
                  value: 'eks-prod-1-p1c-ng',
                },
              ],
              [
                {
                  value: 'eks-prod-1-p1en-ng',
                },
              ],
            ],
          },
          {
            type: 'conversation',
          },
        ],
      },
    ],
  },
  {
    stage_number: 2,
    name: 'Learn What’s Changed',
    status: 'active',
    allowed_action_types: [],
    created: 42,
    updated: 42,
    header_content_blocks: [
      {
        type: 'info-section',
        items: [
          {
            title: 'Version',
            value: 'v1.24',
          },
          {
            title: 'Recommended',
            value: 'v1.25',
          },
          {
            title: 'Provider',
            value: 'Amazon EKS',
          },
          {
            title: 'Region',
            value: 'us-east-2',
          },
          {
            title: 'AddOns',
            value: '13',
          },
          {
            title: 'Applications',
            value: '15',
          },
          {
            title: 'Node Groups',
            value: '2',
          },
          {
            title: 'Upgrade Type',
            value: 'In-Place',
          },
        ],
      },
    ],
    steps: [
      {
        step_number: 1,
        name: 'EKS v1.24 -> v1.25',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Notable Changes',
          },
          {
            type: 'text',
            style: 'normal',
            text: 'The following APIs are no longer served starting v1.25 release',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'batch/v1beta1 API version of CronJob',
              },
              {
                text: 'discovery.k8s.io/v1beta1 API version of EndpointSlice',
              },
              {
                text: 'events.k8s.io/v1beta1 API version of Event',
              },
              {
                text: 'autoscaling/v2beta1 API version of HorizontalPodAutoscaler',
              },
              {
                text: 'policy/v1beta1 API version of PodDisruptionBudget',
              },
              {
                text: 'PodSecurityPolicy in the policy/v1beta1 API version',
              },
              {
                text: 'RuntimeClass in the node.k8s.io/v1beta1 API version',
              },
            ],
          },
          {
            type: 'text',
            style: 'normal',
            text: "PodSecurityPolicy (PSP) is removed in Kubernetes 1.25. PSPs are replaced with Pod Security\nAdmission (PSA) and Pod Security Standards (PSS). PSA is a built-in admission controller that\nimplements the security controls outlined in the PSS . PSA and PSS are graduated to\nstable in Kubernetes 1.25 and are enabled in Amazon EKS by default. If you have PSPs in\nyour cluster, make sure to migrate from PSP to the built-in Kubernetes PSS or to a policy-as-code\nsolution before upgrading your cluster to version 1.25. If you don't migrate from PSP,\nyou might encounter interruptions to your workloads.",
          },
          {
            type: 'learn-more',
            links: [
              {
                text: 'Amazon EKS Kubernetes versions',
                url: 'https://docs.aws.amazon.com/eks/latest/userguide/kubernetes-versions.html',
              },
              {
                text: 'Kubernetes Changelog',
                url: 'https://github.com/kubernetes/kubernetes/tree/master/CHANGELOG',
              },
            ],
          },
        ],
      },
      {
        step_number: 2,
        name: 'VPC CNI v1.11.0 -> v1.12.6',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Reasons to upgrade',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'VPC CNI v1.12.6 is the officially recommended version for Amazon EKS v1.25',
              },
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Notable Changes',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'aws-vpc-cni Helm chart v1.2.0 is released with VPC CNI v1.12.0, thus no longer supports the\n cri.hostPath.path. If you need to install a VPC CNI <v1.12.0 with helm chart, a aws-vpc-cni\nHelm chart with version <v1.2.0 should be used.',
              },
            ],
          },
          {
            type: 'learn-more',
            links: [
              {
                text: 'Amazon VPC CNI for Kubernetes release notes',
                url: 'https://github.com/aws/amazon-vpc-cni-k8s/releases',
              },
            ],
          },
        ],
      },
      {
        step_number: 3,
        name: 'Core DNS v1.8.7 -> v1.9.3',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Reasons to upgrade',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'Core DNS v1.9.3 is the officially recommended version for Amazon EKS v1.25',
              },
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Notable Changes',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'The support for wildcard queries was deprecated in CoreDNS 1.8.7 and removed in CoreDNS 1.9.\\n This was done as a security measure. Wildcard queries no longer work and return\\n NXDOMAIN instead of an IP address.',
              },
            ],
          },
          {
            type: 'learn-more',
            links: [
              {
                text: 'CoreDNS release notes',
                url: 'https://github.com/coredns/coredns/releases',
              },
            ],
          },
        ],
      },
      {
        step_number: 4,
        name: 'AWS Load Balancer Controller v2.4.4 -> 2.4.7',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Reasons to upgrade',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'v2.4.7 supports discovery.k8s.io/v1 version of EndpointSlice for compatibility with k8s 1.25 and later releases.',
              },
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Notable Changes',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'The controller now supports discovery.k8s.io/v1 version of EndpointSlice for compatibility with k8s 1.25 and later releases.',
              },
              {
                text: 'Updated the reference IAM policies to explicitly add the AddTag permission for creating load balancer and listener resources.',
              },
            ],
          },
          {
            type: 'learn-more',
            links: [
              {
                text: 'AWS Load Balancer Controller release notes',
                url: 'https://github.com/kubernetes-sigs/aws-load-balancer-controller/releases',
              },
            ],
          },
        ],
      },
      {
        step_number: 5,
        name: 'KubeProxy v1.24.7 → v1.25.6',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Reasons to upgrade',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'KubeProxy v1.25.6 is the officially recommended version for Amazon EKS v1.25',
              },
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Notable Changes',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'AWS released a managed add-on for KubeProxy. You should consider moving to this managed add-on.',
              },
              {
                text: 'Kube-proxy images are now based on distroless images. kube-proxy container images were built using Debian as the base image. Starting with this release, the images are now built using distroless. This change reduced image size by almost 50% and decreased the number of installed packages and files to only those strictly required for kube-proxy to do its job.',
              },
            ],
          },
          {
            type: 'learn-more',
            links: [
              {
                text: 'Kubernetes release notes',
                url: 'https://github.com/kubernetes/kubernetes/tree/master/CHANGELOG',
              },
            ],
          },
        ],
      },
      {
        step_number: 6,
        name: 'Cert Manager v1.9 → v1.11',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Reasons to upgrade',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'cert-manager v1.9 is not compatible with Kubernetes v1.25. You need to upgrade to cert-manager v1.10 or later. v1.10 reached the end of support on May 19, 2023. v1.11 is the currently supported version.',
              },
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Notable Changes',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'Container name changes for Helm or the static manifest files. v1.10.0 changes the names of containers in pods created by cert-manager. The names are changed to better reflect what they do; for example, the container in the controller pod had its name changed from cert-manager to cert-manager-controller, and the webhook pod had its container name changed from cert-manager to cert-manager-webhook.',
              },
              {
                text: 'There are no breaking changes between cert-manager v1.10 and v1.11. You’re not using the experimental Gateway API support in cert-manager, which changed the Gateway API version in v1.11.',
              },
            ],
          },
          {
            type: 'learn-more',
            links: [
              {
                text: 'Cert Manager release notes',
                url: 'https://cert-manager.io/docs/release-notes/',
              },
            ],
          },
        ],
      },
      {
        step_number: 7,
        name: 'External DNS v0.10.1 -> v0.13.4',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Reasons to upgrade',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'You’re running a 2-year-old version of External DNS. There have been various bug fixes and vulnerabilities since the v0.10.1 release. We recommend upgrading to v0.13.4.',
              },
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Notable Changes',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'Fix merging of endpoints. Currently, services with the same hostname annotation but different set identifiers get merged, so not all the DNS records specified using annotations get created, i.e. when specifying weighted Route53 DNS records.',
              },
              {
                text: "Fix CNAME record when multiple router canonical names are defined in Route Status. When you have multiple ingress controllers, the route's statuses Ingress object get populated with multiple router canonical names. So in this case, the external DNS tries to add multiple CNAME records for the same host in the same hosted zone, which is a violation of RFC 1912 and therefore is rejected by standards-compliant DNS services.",
              },
            ],
          },
          {
            type: 'learn-more',
            links: [
              {
                text: 'External DNS release notes',
                url: 'https://github.com/kubernetes-sigs/external-dns/releases',
              },
            ],
          },
        ],
      },
      {
        step_number: 8,
        name: 'AWS EBS CSI Driver v1.5.2 → v1.19.0',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Reasons to upgrade',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'AWS EBS CSI Driver v1.19.0 is the officially recommended version for Amazon EKS v1.25',
              },
              {
                text: 'You’re running a 1-year-old version of EBS CSI Driver. There have been various bug fixes and vulnerabilities since the v1.5.2 release. We recommend upgrading to v1.19.0',
              },
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Notable Changes',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: "To improve the security of the container images, the base image will be switched from Amazon Linux 2 to EKS Distro Minimal. The new minimal base image only contains the necessary driver dependencies which means it will not include a shell. Please be aware that while this change won't break workloads, it may break processes for debugging if you are using a shell.",
              },
              {
                text: 'Fix volume limits for m6id and x2idn instance types',
              },
              {
                text: 'The GCR manifests now use registry.k8s.io instead of k8s.gcr.io for the image repository.',
              },
              {
                text: 'Fix improper handling of manually-mounted volumes. Device Manager treats the prefixes "/dev/xvd" and "/dev/sd" as magic. This leads to a bug where volumes with a prefix starting with "/dev/sd" (for example, "/dev/sdf") will get incorrectly mangled into a "/dev/xvd" form (i.e. converting "/dev/sdf" to "/dev/xvdf"). This will cause a volume that is already mounted and prefixed with "/dev/sd" (for example, a volume manually mounted via the AWS console) to get stuck in an infinite loop as the node will believe it is mounted at the wrong path.',
              },
            ],
          },
          {
            type: 'learn-more',
            links: [
              {
                text: 'AWS EBS CSI Driver release notes',
                url: 'https://github.com/kubernetes-sigs/aws-ebs-csi-driver/blob/master/CHANGELOG.md',
              },
            ],
          },
        ],
      },
      {
        step_number: 9,
        name: 'Applications api-worker, asset-manager, nightly-verif, and fujin-simulator changes',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Reasons to update',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'nightly-verif is using batch/v1beta1 API, which is no longer served in v1.25. Upgrade to batch/v1',
              },
              {
                text: 'assert-manager is using policy/v1beta1 API, which is no longer served in v1.25. Upgrade to policy/v1',
              },
              {
                text: 'api-worker is using discovery.k8s.io/v1beta1 API, which is no longer served in v1.25. Upgrade to discovery.k8s.io/v1',
              },
              {
                text: 'fujin-simulator is using batch/v1beta1 API, which is no longer served in v1.25. Upgrade to batch/v1',
              },
            ],
          },
          {
            type: 'learn-more',
            links: [
              {
                text: 'Kubernetes API deprecation and removal notes',
                url: 'https://kubernetes.io/docs/reference/using-api/deprecation-guide/',
              },
            ],
          },
        ],
      },
      {
        step_number: 10,
        name: 'Node Groups Upgrade',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Reasons to update',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: '1.25.x.y is the supported AMI version for Amazon EKS v1.25',
              },
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Notable Changes',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'The EKS Optimized AMI for 1.25 no longer includes support for Dockershim.',
              },
              {
                text: 'Kernel version upgrade to fix the issue of containers failing to create and probe exec errors related to seccomp on recent kernel-5.10 versions issue',
              },
              {
                text: 'kernel: 5.10.179-166.674.amzn2',
              },
              {
                text: 'containerd: 1.6.19-1.amzn2.0.1',
              },
              {
                text: 'runc: 1.1.4-1.amzn2',
              },
              {
                text: 'cuda: 11.4.0-1',
              },
              {
                text: 'nvidia-container-runtime-hook: 1.4.0-1.amzn2',
              },
              {
                text: 'amazon-ssm-agent: 3.1.1732.0-1.amzn2',
              },
            ],
          },
          {
            type: 'learn-more',
            links: [
              {
                text: 'EKS AMI Release Notes',
                url: 'https://github.com/awslabs/amazon-eks-ami/blob/master/CHANGELOG.md',
              },
            ],
          },
        ],
      },
      {
        step_number: 11,
        name: 'IAM Role Permissions',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Reasons to update',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'AWS Load Balancer Controller has updated the reference IAM policies to explicitly add the AddTag permission for creating load balancer and listener resources.',
              },
            ],
          },
          {
            type: 'learn-more',
            links: [
              {
                text: 'AWS Load Balancer Controller 2.4.7 release note',
                url: 'https://github.com/kubernetes-sigs/aws-load-balancer-controller/releases/tag/v2.4.7',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    stage_number: 3,
    name: 'Preflight Checks',
    status: 'active',
    allowed_action_types: [],
    created: 42,
    updated: 42,
    header_content_blocks: [
      {
        type: 'info-section',
        items: [
          {
            title: 'Version',
            value: 'v1.24',
          },
          {
            title: 'Recommended',
            value: 'v1.25',
          },
          {
            title: 'Provider',
            value: 'Amazon EKS',
          },
          {
            title: 'Region',
            value: 'us-east-2',
          },
          {
            title: 'AddOns',
            value: '13',
          },
          {
            title: 'Applications',
            value: '15',
          },
          {
            title: 'Node Groups',
            value: '2',
          },
          {
            title: 'Upgrade Type',
            value: 'In-Place',
          },
        ],
      },
    ],
    steps: [
      {
        step_number: 1,
        name: 'Verify Cluster and Node Group Health',
        description: '',
        allowed_action_types: [],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Clusters',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Health Check Status',
              },
            ],
            data: [
              [
                {
                  value: 'eks-prod-1',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Node Groups',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Health Check Status',
              },
            ],
            data: [
              [
                {
                  value: 'prod-1-critical',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'prod-1-enhanced-network',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
          },
        ],
      },
      {
        step_number: 2,
        name: 'Verify Addon Health',
        description: '',
        allowed_action_types: [],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Addons',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Health Check Status',
              },
            ],
            data: [
              [
                {
                  value: 'Argo CD',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Grafana',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Prometheus',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'RabbitMQ',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Node Problem Detector',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Open Cost',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'VPC CNI',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Kube Proxy',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Core DNS',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'AWS Load Balancer Controller',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Cert Manager',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'External DNS',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'EBS CSI Driver',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
          },
        ],
      },
      {
        step_number: 3,
        name: 'Take a Backup',
        description: '',
        badges: [
          {
            variant: 'neutral',
            text: 'optional',
          },
        ],
        allowed_action_types: [
          'mark-completed',
          'undo-mark-completed',
          'skip',
          'undo-skip',
        ],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'normal',
            text: 'Take a backup of the cluster state using Velero or another tool. Taking a backup will ensure smooth rollbacks to last-known-stable-config in case of hitting an upgrade failure.',
          },
          {
            type: 'conversation',
          },
        ],
      },
      {
        step_number: 4,
        name: 'Verify EKS Upgrade Requirements',
        description:
          'Let’s make sure all the EKS cluster upgrade requirements are met',
        allowed_action_types: [],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Requirement',
              },
              {
                title: 'Status',
              },
            ],
            data: [
              [
                {
                  value: 'Available IP addresses',
                  info_text:
                    'To update the cluster, Amazon EKS requires up to five available IP addresses from the subnets that you specified when you created your cluster',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'EKS IAM role',
                  info_text:
                    'Make sure the control plane IAM role is still present in the account with the necessary permissions.',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'EKS security group',
                  info_text:
                    'Make sure the control plane primary security group is still available in the account with the necessary access rules.',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Subnets across different AZs',
                  info_text:
                    'Make sure there are at least 2 subnets in different availability zones, each with at least 5 available IPs for the control plane to upgrade.',
                },
                {
                  value: 'Pass',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
          },
        ],
      },
      {
        step_number: 5,
        name: 'Notify Stakeholders',
        description: '',
        allowed_action_types: [],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'normal',
            text: 'Notify relevant stakeholders (business, product and application owners) about the upcoming\n upgrade and any expected impact or downtime. Communicate the timeline, expected duration, \nand potential risks associated with the upgrade to ensure everyone is well-informed.',
          },
        ],
      },
    ],
  },
  {
    stage_number: 4,
    name: 'Upgrade',
    status: 'active',
    allowed_action_types: [],
    created: 42,
    updated: 42,
    header_content_blocks: [
      {
        type: 'info-section',
        items: [
          {
            title: 'Version',
            value: 'v1.24',
          },
          {
            title: 'Recommended',
            value: 'v1.25',
          },
          {
            title: 'Provider',
            value: 'Amazon EKS',
          },
          {
            title: 'Region',
            value: 'us-east-2',
          },
          {
            title: 'AddOns',
            value: '13',
          },
          {
            title: 'Applications',
            value: '15',
          },
          {
            title: 'Node Groups',
            value: '2',
          },
          {
            title: 'Upgrade Type',
            value: 'In-Place',
          },
        ],
      },
    ],
    steps: [
      {
        step_number: 1,
        name: 'Update Clients',
        description: '',
        allowed_action_types: [
          'mark-completed',
          'undo-mark-completed',
          'skip',
          'undo-skip',
        ],
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Update kubectl',
          },
          {
            type: 'text',
            style: 'normal',
            text: 'Mac (Homebrew).',
          },
          {
            type: 'code',
            code: 'brew upgrade kubectl',
          },
          {
            type: 'text',
            style: 'normal',
            text: 'Linux (amd64).',
          },
          {
            type: 'code',
            code: 'curl -O https://s3.us-west-2.amazonaws.com/amazon-eks/1.25.9/2023-05-11/bin/linux/amd64/kubectl && chmod +x ./kubectl && mkdir -p $HOME/bin && cp ./kubectl $HOME/bin/kubectl && export PATH=$HOME/bin:$PATH',
          },
          {
            type: 'text',
            style: 'normal',
            text: 'Linux (arm64).',
          },
          {
            type: 'code',
            code: 'curl -O https://s3.us-west-2.amazonaws.com/amazon-eks/1.25.9/2023-05-11/bin/linux/arm64/kubectl && chmod +x ./kubectl && mkdir -p $HOME/bin && cp ./kubectl $HOME/bin/kubectl && export PATH=$HOME/bin:$PATH',
          },
        ],
        status: 'active',
        created: 42,
        updated: 42,
      },
      {
        step_number: 2,
        name: 'Upgrade Cert Manager',
        badges: [
          {
            variant: 'warning',
            text: 'required',
          },
        ],
        description:
          "Let's upgrade Cert Manager before the control plane upgrade. cert-manager v1.9 is not compatible with EKS v1.25",
        allowed_action_types: [
          'mark-completed',
          'undo-mark-completed',
          'skip',
          'undo-skip',
        ],
        content_blocks: [
          {
            type: 'text',
            style: 'normal',
            text: 'Clicking this button will create a Pull Request (PR) in your IaC repository with the diff. Requires you to install "Integrations > GitHub"',
          },
        ],
        status: 'active',
        created: 42,
        updated: 42,
      },
      {
        step_number: 3,
        name: 'Upgrade Control Plane',
        badges: [
          {
            variant: 'warning',
            text: 'required',
          },
        ],
        description: '',
        allowed_action_types: [
          'mark-completed',
          'undo-mark-completed',
          'skip',
          'undo-skip',
        ],
        content_blocks: [
          {
            type: 'text',
            style: 'normal',
            text: "Here's a sample code diff you have make in your IaC. Then please run terraform validate, plan, and apply",
          },
          {
            type: 'code',
            code: 'module "eks" {\n  source  = "terraform-aws-modules/eks/aws"\n  version = "19.5.1"\n\n  cluster_name    = local.cluster_name\n  cluster_version = "1.25"',
          },
          {
            type: 'code',
            code: 'terraform validate && terraform plan',
          },
          {
            type: 'code',
            code: 'terraform validate && terraform apply',
          },
        ],
        status: 'active',
        created: 42,
        updated: 42,
      },
      {
        step_number: 4,
        name: 'Upgrade Node Groups',
        badges: [
          {
            variant: 'warning',
            text: 'required',
          },
        ],
        description: '',
        allowed_action_types: [
          'mark-completed',
          'undo-mark-completed',
          'skip',
          'undo-skip',
        ],
        content_blocks: [
          {
            type: 'text',
            style: 'normal',
            text: "Here's a sample code diff you have make in your IaC. Then please run terraform validate, plan, and apply",
          },
          {
            type: 'code',
            code: '  eks_managed_node_groups = {\n    one = {\n      name = "prod-1-critical"\n      ami_release_version = "1.25.9-20230526"\n    }\n\n    two = {\n      name = "prod-1-enhanced-network"\n      ami_release_version = "1.25.9-20230526"\n    }',
          },
          {
            type: 'code',
            code: 'terraform validate && terraform plan',
          },
          {
            type: 'code',
            code: 'terraform validate && terraform apply',
          },
        ],
        status: 'active',
        created: 42,
        updated: 42,
      },
      {
        step_number: 5,
        name: 'Upgrade Remaining Addons',
        badges: [
          {
            variant: 'warning',
            text: 'required',
          },
        ],
        description: '',
        allowed_action_types: [
          'mark-completed',
          'undo-mark-completed',
          'skip',
          'undo-skip',
        ],
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Upgrade VPC CNI, KubeProxy, CoreDNS and EBS CSI Driver',
          },
          {
            type: 'text',
            style: 'normal',
            text: "Here's a sample code diff you have make in your IaC. Then please run terraform validate, plan, and apply",
          },
          {
            type: 'code',
            code: 'resource "aws_eks_addon" "ebs-csi" {\n  cluster_name             = module.eks.cluster_name\n  addon_name               = "aws-ebs-csi-driver"\n  addon_version            = "v1.19.0-eksbuild.1"\n  service_account_role_arn = module.irsa-ebs-csi.iam_role_arn\n  tags = {\n    "eks_addon" = "ebs-csi"\n    "terraform" = "true"\n  }\n}\n\nresource "aws_eks_addon" "vpc_cni" {\n  cluster_name             = module.eks.cluster_name\n  addon_name               = "vpc-cni"\n  addon_version            = "v1.12.6-eksbuild.2"\n  resolve_conflicts = "OVERWRITE"\n\n  tags = {\n    "eks_addon" = "vpc-cni"\n    "terraform" = "true"\n  }\n}\n\nresource "aws_eks_addon" "core_dns" {\n  cluster_name             = module.eks.cluster_name\n  addon_name               = "coredns"\n  addon_version            = "v1.9.3-eksbuild.3"\n  resolve_conflicts = "OVERWRITE"\n\n  tags = {\n    "eks_addon" = "coredns"\n    "terraform" = "true"\n  }\n}\n\nresource "aws_eks_addon" "kube_proxy" {\n  cluster_name             = module.eks.cluster_name\n  addon_name               = "kube-proxy"\n  addon_version            = "v1.25.6-minimal-eksbuild.2"\n  resolve_conflicts = "OVERWRITE"\n\n  tags = {\n    "eks_addon" = "kube-proxy"\n    "terraform" = "true"\n  }\n}',
          },
          {
            type: 'code',
            code: 'terraform validate && terraform plan',
          },
          {
            type: 'code',
            code: 'terraform validate && terraform apply',
          },
        ],
        status: 'active',
        created: 42,
        updated: 42,
      },
      {
        step_number: 6,
        name: 'Update IAM Role Permissions',
        badges: [
          {
            variant: 'warning',
            text: 'required',
          },
        ],
        description: '',
        allowed_action_types: [
          'mark-completed',
          'undo-mark-completed',
          'skip',
          'undo-skip',
        ],
        content_blocks: [
          {
            type: 'text',
            style: 'normal',
            text: 'Clicking this button will create a Pull Request (PR) in your IaC repository with the diff. Requires you to install "Integrations > GitHub"',
          },
        ],
        status: 'active',
        created: 42,
        updated: 42,
      },
      {
        step_number: 7,
        name: 'Update Applications',
        badges: [
          {
            variant: 'warning',
            text: 'required',
          },
        ],
        description: '',
        allowed_action_types: [
          'mark-completed',
          'undo-mark-completed',
          'skip',
          'undo-skip',
        ],
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Update API versions for api-worker, asset-manager, nightly-verif, fujin-simulator',
          },
          {
            type: 'text',
            style: 'normal',
            text: 'nightly-verif: change API version to batch/v1\nassert-manager: change API version topolicy/v1\napi-worker: change API version to discovery.k8s.io/v1\nfujin-simulator: change API version to batch/v1',
          },
          {
            type: 'text',
            style: 'normal',
            text: 'Clicking this button will create a Pull Request (PR) in your IaC repository with the diff. Requires you to install "Integrations > GitHub"',
          },
        ],
        status: 'active',
        created: 42,
        updated: 42,
      },
    ],
  },
  {
    stage_number: 5,
    name: 'Postflight Checks',
    status: 'active',
    allowed_action_types: [],
    created: 42,
    updated: 42,
    header_content_blocks: [
      {
        type: 'info-section',
        items: [
          {
            title: 'Version',
            value: 'v1.24',
          },
          {
            title: 'Recommended',
            value: 'v1.25',
          },
          {
            title: 'Provider',
            value: 'Amazon EKS',
          },
          {
            title: 'Region',
            value: 'us-east-2',
          },
          {
            title: 'AddOns',
            value: '13',
          },
          {
            title: 'Applications',
            value: '15',
          },
          {
            title: 'Node Groups',
            value: '2',
          },
          {
            title: 'Upgrade Type',
            value: 'In-Place',
          },
        ],
      },
    ],
    steps: [
      {
        step_number: 1,
        name: 'Verify Cluster and Node Group Health',
        description: '',
        allowed_action_types: [],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Clusters',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Health Check Status',
              },
            ],
            data: [
              [
                {
                  value: 'eks-prod-1',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
          },
          {
            type: 'spacer',
          },
          {
            type: 'text',
            style: 'header',
            text: 'Node Groups',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Health Check Status',
              },
            ],
            data: [
              [
                {
                  value: 'prod-1-critical',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'prod-1-enhanced-network',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
          },
        ],
      },
      {
        step_number: 2,
        name: 'Verify Addon Health',
        description: '',
        allowed_action_types: [],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Addons',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Health Check Status',
              },
            ],
            data: [
              [
                {
                  value: 'Argo CD',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Grafana',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Prometheus',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'RabbitMQ',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Node Problem Detector',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Open Cost',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'VPC CNI',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Kube Proxy',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Core DNS',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'AWS Load Balancer Controller',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Cert Manager',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'External DNS',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'EBS CSI Driver',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
          },
        ],
      },
      {
        step_number: 3,
        name: 'Verify Application Health',
        description: '',
        allowed_action_types: [],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Applications',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Health Check Status',
              },
            ],
            data: [
              [
                {
                  value: 'api',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'cartwheel',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'catalog',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'checkout-manager',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'dashboard',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'frontend',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'order-processor',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'spam-detector',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'billing-manager',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'custom-authorizer',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'dionysus-daemon',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'api-worker',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'asset-manager',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'nightly-verif',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'fujin-simulator',
                },
                {
                  value: 'Healthy',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
          },
        ],
      },
      {
        step_number: 4,
        name: 'Verify Ingress and Load Balancer Reconciliation',
        description: '',
        allowed_action_types: [],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'header',
            text: 'Ingresses',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Name',
              },
              {
                title: 'Health Check Status',
              },
            ],
            data: [
              [
                {
                  value: 'api-svc-ingress',
                },
                {
                  value: 'Reconciled',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'dashboard-svc-ingress',
                },
                {
                  value: 'Reconciled',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'catalog-svc-ingress',
                },
                {
                  value: 'Reconciled',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
          },
        ],
      },
      {
        step_number: 5,
        name: 'Verify No Availability Risks Need Attention',
        description: '',
        allowed_action_types: [],
        status: 'active',
        created: 42,
        updated: 42,
        content_blocks: [
          {
            type: 'text',
            style: 'normal',
            text: 'Here is the summary of Availability Risks that need your attention',
          },
          {
            type: 'table',
            column_definitions: [
              {
                title: 'Risk Type',
              },
              {
                title: 'Need Attention Risk Count',
              },
            ],
            data: [
              [
                {
                  value: 'Unsupported Versions',
                },
                {
                  value: '0',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Defects',
                },
                {
                  value: '0',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Deprecations',
                },
                {
                  value: '0',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Version Incompatibilities',
                },
                {
                  value: '0',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'Misconfigurations',
                },
                {
                  value: '0',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
              [
                {
                  value: 'System Requirements',
                },
                {
                  value: '0',
                  icon: {
                    variant: 'success',
                  },
                },
              ],
            ],
          },
        ],
      },
    ],
  },
];
