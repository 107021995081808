import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  Spinner,
  SplitPanel,
  TextContent,
} from '@cloudscape-design/components';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { format } from 'date-fns';
import { useState } from 'react';
import { splitPanelI18nStrings } from 'src/i18n/split-panel';

import { updateUpgrade, useGetUpgrade } from 'api/admin';

import CopyText from 'components/copy-text';
import { UpgradePlanEditor } from './editor';
import {
  ActionButton,
  UpgradeBadgeStatus,
  TemporalBadgeStatus,
} from './helpers';
import {
  ValueWithLabel,
  ValueWithLabelEditButton,
} from 'components/value-with-label';
import HeaderItemsTable from './header';

export function UpgradeDetails({
  upgrade: item,
  onChange,
}: {
  upgrade: { accountId: string; upgradeId: string };
  onChange: () => void;
}) {
  const { data, mutate } = useGetUpgrade(item.accountId, item.upgradeId);
  const [editorMode, setEditorMode] = useState<'edit' | 'create' | 'hidden'>(
    'hidden',
  );

  if (data === undefined) {
    return (
      <SplitPanel
        header={`Upgrade: ${item.upgradeId}`}
        i18nStrings={splitPanelI18nStrings}
      >
        <Spinner />
      </SplitPanel>
    );
  }

  return (
    <SplitPanel
      header={`Upgrade: ${data.upgrade.name}`}
      i18nStrings={splitPanelI18nStrings}
    >
      <UpgradePlanEditor
        mode={editorMode === 'hidden' ? 'edit' : editorMode}
        accountId={item.accountId}
        upgradeId={item.upgradeId}
        visible={editorMode !== 'hidden'}
        onCancel={() => setEditorMode('hidden')}
        onConfirm={() => {
          setEditorMode('hidden');
          mutate()
            .then(() => onChange())
            .catch((err) => console.log(err));
        }}
      />
      <SpaceBetween size="l">
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {data.upgrade.status === 'in-progress' &&
                    data.creation_status === 'published' && (
                      <>
                        <ActionButton
                          key="mark-as-complete"
                          onConfirm={async () => {
                            if (
                              confirm(
                                `Are you sure you want to mark this upgrade as completed?`,
                              )
                            ) {
                              await updateUpgrade(
                                data.account_id,
                                data.upgrade.id,
                                {
                                  status: 'completed',
                                },
                              );
                              mutate()
                                .then(() => onChange())
                                .catch((err) => console.log(err));
                            }
                          }}
                        >
                          Mark As Completed
                        </ActionButton>
                        <ActionButton
                          key="mark-as-outdated"
                          onConfirm={async () => {
                            await updateUpgrade(
                              data.account_id,
                              data.upgrade.id,
                              {
                                status: 'outdated',
                              },
                            );
                            mutate()
                              .then(() => onChange())
                              .catch((err) => console.log(err));
                          }}
                        >
                          Mark As Outdated
                        </ActionButton>
                      </>
                    )}
                  <ActionButton
                    key="assign-to-me"
                    onConfirm={async () => {
                      const user =
                        (await Auth.currentAuthenticatedUser()) as CognitoUser;
                      const session = user.getSignInUserSession();
                      await updateUpgrade(data.account_id, data.upgrade.id, {
                        assignee: session?.getIdToken().payload.email as string,
                        creation_status: 'in-progress',
                      });
                      mutate()
                        .then(() => onChange())
                        .catch((err) => console.log(err));
                    }}
                  >
                    Assign to me
                  </ActionButton>
                  {data.upgrade.status === 'pending' &&
                    data.creation_status === 'in-progress' && (
                      <ActionButton
                        key="publish-for-review"
                        onConfirm={async () => {
                          if (data.upgrade.status === 'pending') {
                            await updateUpgrade(
                              data.account_id,
                              data.upgrade.id,
                              {
                                creation_status: 'in-review',
                              },
                            );
                            mutate()
                              .then(() => onChange())
                              .catch((err) => console.log(err));
                          }
                        }}
                      >
                        Publish for review
                      </ActionButton>
                    )}
                  {data.upgrade.status === 'pending' &&
                    data.creation_status === 'in-review' && (
                      <ActionButton
                        key="publish-to-customer"
                        onConfirm={async () => {
                          if (data.upgrade.status === 'pending') {
                            await updateUpgrade(
                              data.account_id,
                              data.upgrade.id,
                              {
                                creation_status: 'published',
                                status: 'in-progress',
                              },
                            );
                            mutate()
                              .then(() => onChange())
                              .catch((err) => console.log(err));
                          }
                        }}
                      >
                        Publish to customer
                      </ActionButton>
                    )}
                  {data.upgrade.status === 'update-in-progress' &&
                    data.creation_status === 'published' && (
                      <ActionButton
                        key="republish-to-customer"
                        onConfirm={async () => {
                          await updateUpgrade(
                            data.account_id,
                            data.upgrade.id,
                            {
                              last_validated: Date.now(),
                              status: 'in-progress',
                            },
                          );
                          mutate()
                            .then(() => onChange())
                            .catch((err) => console.log(err));
                        }}
                      >
                        Re-Publish to customer
                      </ActionButton>
                    )}
                  {data.temporal_status && (
                    <Button
                      key="show-plan"
                      onClick={() => {
                        setEditorMode('edit');
                      }}
                    >
                      Show Upgrade Plan JSON
                    </Button>
                  )}
                  {(!data.temporal_status ||
                    data.temporal_status === 'Terminated') && (
                    <Button
                      key="create-plan"
                      onClick={() => setEditorMode('create')}
                    >
                      Create Upgrade Plan
                    </Button>
                  )}
                </SpaceBetween>
              }
            >
              Upgrade details
            </Header>
          }
        >
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label="Account ID">
                <CopyText
                  copyText={data.account_id}
                  copyButtonLabel="Copy Account ID"
                  successText="Account ID copied"
                  errorText="Account ID failed to copy"
                />
              </ValueWithLabel>
              <ValueWithLabel label="ID">
                <CopyText
                  copyText={data.upgrade.id}
                  copyButtonLabel="Copy Upgrade ID"
                  successText="Upgrade ID copied"
                  errorText="Upgrade ID failed to copy"
                />
              </ValueWithLabel>
            </SpaceBetween>
            {data.upgrade.upgrade_template_id && (
              <ValueWithLabel label="Template ID">
                <CopyText
                  copyText={data.upgrade.upgrade_template_id}
                  copyButtonLabel="Copy Upgrade Template ID"
                  successText="Upgrade Template ID copied"
                  errorText="Upgrade Template ID failed to copy"
                />
              </ValueWithLabel>
            )}
            <SpaceBetween size="l">
              <ValueWithLabel label="Name">{data.upgrade.name}</ValueWithLabel>
              <ValueWithLabel label="Requested By">
                {data.upgrade.requested_by?.name || '-'} (
                {data.upgrade.requested_by?.email || '-'})
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Status">
                <UpgradeBadgeStatus value={data.upgrade.status} />
              </ValueWithLabel>
              <ColumnLayout columns={2} variant="text-grid">
                <ValueWithLabel label="Created">
                  {format(data.upgrade.created, 'Pp')}
                </ValueWithLabel>
                {data.upgrade.updated && (
                  <ValueWithLabel label="Last Update">
                    {format(data.upgrade.updated, 'Pp')}
                  </ValueWithLabel>
                )}
              </ColumnLayout>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
        <Container header={<Header variant="h2">Creation Details</Header>}>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="xs">
              <ValueWithLabel label="Assignee">
                {data.assignee || '-'}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="xs">
              <ValueWithLabel label="Creation Status">
                <UpgradeBadgeStatus value={data.creation_status} />
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="xs">
              <ValueWithLabel label="Temporal Status">
                <TemporalBadgeStatus value={data.temporal_status} />
              </ValueWithLabel>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
        <Container header={<Header variant="h2">Resources</Header>}>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="xs">
              <Box variant="awsui-key-label">Clusters</Box>
              <TextContent>
                {(data.upgrade.resources.clusters || []).map((c) => (
                  <CopyText
                    key={c.id}
                    copyText={c.id}
                    copyButtonLabel="Copy Cluster ID"
                    successText="Cluster ID copied"
                    errorText="Cluster ID failed to copy"
                  />
                ))}
                {(data.upgrade.resources.clusters === undefined ||
                  data.upgrade.resources.clusters.length === 0) && <p>-</p>}
              </TextContent>
              <ColumnLayout columns={2} variant="text-grid">
                <ValueWithLabel label="Current Version">
                  {data.upgrade.current_version || '-'}
                </ValueWithLabel>
                <ValueWithLabel label="Desired Version">
                  {data.upgrade.desired_version || '-'}
                </ValueWithLabel>
              </ColumnLayout>
            </SpaceBetween>
            <SpaceBetween size="xs">
              <Box variant="awsui-key-label">Addons</Box>
              <TextContent>
                {(data.upgrade.resources.addons || []).map((c) => (
                  <CopyText
                    key={c.id}
                    copyText={c.id}
                    copyButtonLabel="Copy Addon ID"
                    successText="Addon ID copied"
                    errorText="Addon ID failed to copy"
                  />
                ))}
                {(data.upgrade.resources.addons === undefined ||
                  data.upgrade.resources.addons.length === 0) && <p>-</p>}
              </TextContent>
            </SpaceBetween>
            <SpaceBetween size="xs">
              <Box variant="awsui-key-label">Addon Instances</Box>
              <TextContent>
                {(data.upgrade.resources.addon_instances || []).map((c) => (
                  <CopyText
                    key={c.id}
                    copyText={c.id}
                    copyButtonLabel="Copy Addon Instance ID"
                    successText="Addon Instance ID copied"
                    errorText="Addon Instance ID failed to copy"
                  />
                ))}
                {(data.upgrade.resources.addon_instances === undefined ||
                  data.upgrade.resources.addon_instances.length === 0) && (
                  <p>-</p>
                )}
              </TextContent>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
        <HeaderItemsTable
          editable={false}
          initialItems={data.upgrade.header?.items || []}
          onSave={async (newItems) => {
            try {
              await updateUpgrade(data.account_id, data.upgrade.id, {
                header: { items: newItems },
              });
              await mutate();
            } catch (err) {
              console.log(err);
            }
          }}
        />
      </SpaceBetween>
    </SplitPanel>
  );
}
