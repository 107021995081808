import { FlashbarProps } from '@cloudscape-design/components';
import { createContext, useContext } from 'react';

const NotificationContext = createContext<
  ((title: string, message: string, type: FlashbarProps.Type) => void) | null
>(null);

export const NotificationProvider = NotificationContext.Provider;

export function useNotifications() {
  const ctx = useContext(NotificationContext);
  if (!ctx) {
    throw new Error('Missing NotificationProvider');
  }
  return ctx;
}
