import {
  ListUpgradeTemplatesResponse,
  UpgradeTemplateCreationStatus,
  UpgradeUser,
  UpgradeTemplateStatus,
} from 'api/admin-models';

export interface UpgradeTemplatesTableElement {
  AccountId: string;
  Id: string;
  Name: string;
  Environment: string;
  Status: UpgradeTemplateStatus;
  CreationStatus: UpgradeTemplateCreationStatus;
  RequestedBy?: UpgradeUser;
  Assignee?: string;

  Created: number;
  Updated?: number;
}

export function fromApiResponses(
  data: ListUpgradeTemplatesResponse[] | undefined,
): UpgradeTemplatesTableElement[] | undefined {
  if (data === undefined) {
    return undefined;
  }

  const results = data.reduce<UpgradeTemplatesTableElement[]>((acc, res) => {
    const mappedData = res.data.map<UpgradeTemplatesTableElement>((uav) => ({
      AccountId: uav.account_id,
      Id: uav.upgrade.id,
      Name: uav.upgrade.name,
      Environment: uav.upgrade.environment,
      Status: uav.upgrade.status,
      CreationStatus: uav.creation_status,
      Assignee: uav.assignee,
      Created: uav.upgrade.created,
      Updated: uav.upgrade.updated,
      RequestedBy: uav.upgrade.requested_by,
    }));

    return acc.concat(...mappedData);
  }, []);

  return results;
}
