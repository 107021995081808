import { ContentLayout, Header } from '@cloudscape-design/components';
import { useListTablesInfinite } from 'api/analytics-infinite';
import { Table } from 'api/analytics-models';
import { FilterableTable } from 'components/tables/filterable-table';
import { useSplitPanel } from 'contexts/split-panel';
import { MidasTableDetails } from './details';

export function MidasTablesIndex(): JSX.Element {
  const updateSplitPanel = useSplitPanel();
  const { data } = useListTablesInfinite();
  return (
    <ContentLayout header={<Header variant="h1">Midas Tables</Header>}>
      <FilterableTable<Table>
        data={data?.reduce<Table[]>((acc, res) => acc.concat(...res.data), [])}
        variant={'container'}
        columns={[
          {
            id: 'name',
            label: 'Name',
            cell: (item) => item.name,

            defaultVisible: true,
            sortingField: 'name',
            filterOperators: ['=', '!=', ':', '!:'],
          },
          {
            id: 'description',
            label: 'Description',
            cell: (item) => item.description,
            defaultVisible: true,
            sortingField: 'description',
            filterOperators: ['=', '!=', ':', '!:'],
          },
        ]}
        selectionMode="single"
        selectionTrackBy={(item) => item.name}
        onSelectionChange={({ detail }) => {
          updateSplitPanel(
            <MidasTableDetails tableName={detail.selectedItems[0].name} />,
          );
        }}
      />
    </ContentLayout>
  );
}
