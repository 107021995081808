import {
  Box,
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Icon,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Spinner,
  SplitPanel,
  Tabs,
} from '@cloudscape-design/components';
import { splitPanelI18nStrings } from 'src/i18n/split-panel';

import {
  updateCluster,
  updateLARStatus,
  useGetCluster,
  useListCloudConnections,
  useListLARStatus,
} from 'api/admin';

import CopyText from 'components/copy-text';

import {
  ValueWithLabel,
  ValueWithLabelEditButton,
} from 'components/value-with-label';
import { format } from 'date-fns';
import { ActionButton } from 'pages/upgrade_plans/helpers';
import { ClusterBadgeStatus } from './clusters';
import { FilterableTable } from 'components/tables/filterable-table';
import { LARStatus } from 'api/admin-models';
import { useEffect, useState } from 'react';

interface AdminLarStatus extends LARStatus {
  rejected: boolean;
}

function CreateNewLarStatusModal(props: {
  accountId: string;
  clusterId: string;
  visible: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}) {
  const [formState, setFormState] = useState<{
    loading: boolean;
    larId: string;
    rejected: boolean;
  }>({ loading: false, larId: '', rejected: false });

  const confirmHandler = async () => {
    try {
      setFormState((prior) => ({ ...prior, loading: true }));
      await updateLARStatus(props.accountId, props.clusterId, formState.larId, {
        rejected: formState.rejected,
      });
      props.onConfirm();
    } catch (err) {
      console.error(err);
    } finally {
      setFormState((prior) => ({ ...prior, loading: false }));
    }
  };

  useEffect(() => {
    setFormState({ larId: '', rejected: false, loading: false });
  }, [props.visible]);

  return (
    <Modal
      onDismiss={props.onDismiss}
      visible={props.visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={props.onDismiss}
              loading={formState.loading}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={formState.loading}
              onClick={confirmHandler}
              disabled={!formState.larId}
            >
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Create new LAR Status"
    >
      <SpaceBetween size="s">
        <FormField label="LAR ID">
          <Input
            value={formState.larId}
            onChange={({ detail }) =>
              setFormState((prior) => ({ ...prior, larId: detail.value }))
            }
          />
        </FormField>
        <FormField label="Rejected">
          <Checkbox
            onChange={({ detail }) =>
              setFormState((prior) => ({ ...prior, rejected: detail.checked }))
            }
            checked={formState.rejected}
          >
            Rejected
          </Checkbox>
        </FormField>
      </SpaceBetween>
    </Modal>
  );
}

export function ClusterDetails({
  cluster: item,
}: {
  cluster: { accountId: string; clusterId: string };
}) {
  const { data, mutate } = useGetCluster(item.accountId, item.clusterId);
  const { data: listLARData, mutate: listLARMutate } = useListLARStatus(
    item.accountId,
    item.clusterId,
  );

  const [selectedItem, setSelectedItem] = useState<AdminLarStatus | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [createLarStatusDialogOpen, setCreateLarStatusDialogOpen] =
    useState<boolean>(false);
  const [
    cloudProviderClusterNameDialogOpen,
    setCloudProviderClusterNameDialogOpen,
  ] = useState<boolean>(false);
  const [cloudConnectionIdDialogOpen, setCloudConnectionIdDialogOpen] =
    useState<boolean>(false);

  if (data === undefined) {
    return (
      <SplitPanel
        header={`Cluster: ${item.clusterId}`}
        i18nStrings={splitPanelI18nStrings}
      >
        <Spinner />
      </SplitPanel>
    );
  }

  return (
    <SplitPanel
      header={`Cluster: ${data.name || data.id}`}
      i18nStrings={splitPanelI18nStrings}
    >
      <SpaceBetween size="l">
        <CloudProviderClusterNameEditor
          accountId={item.accountId}
          clusterId={item.clusterId}
          cloudProviderClusterName={
            (data.cloud_provider_cluster_name as string) || ''
          }
          onDismiss={() => {
            // Refresh the data
            void mutate();
            setCloudProviderClusterNameDialogOpen(false);
          }}
          visible={cloudProviderClusterNameDialogOpen}
        ></CloudProviderClusterNameEditor>
        <CloudConnectionIdEditor
          accountId={item.accountId}
          clusterId={item.clusterId}
          cloudConnectionId={(data.cloud_connection_id as string) || ''}
          onDismiss={() => {
            // Refresh the data
            void mutate();
            setCloudConnectionIdDialogOpen(false);
          }}
          visible={cloudConnectionIdDialogOpen}
        ></CloudConnectionIdEditor>
        <Tabs
          variant="container"
          tabs={[
            {
              id: 'cluster-details',
              label: 'Cluster Details',
              content: (
                <ColumnLayout columns={3} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Account ID">
                      <CopyText
                        copyText={item.accountId}
                        copyButtonLabel="Copy Account ID"
                        successText="Account ID copied"
                        errorText="Account ID failed to copy"
                      />
                    </ValueWithLabel>
                    <SpaceBetween size="xxl" direction="horizontal">
                      <ValueWithLabel label="Chkk Cluster Name">
                        {data?.name || 'n/a'}
                      </ValueWithLabel>
                      <ValueWithLabelEditButton
                        label="Cloud Provider Cluster Name"
                        onConfirm={() =>
                          setCloudProviderClusterNameDialogOpen(true)
                        }
                      >
                        {data?.cloud_provider_cluster_name || 'n/a'}
                      </ValueWithLabelEditButton>
                    </SpaceBetween>
                    <ValueWithLabel label="K8s Provider Release">
                      {data.k8s_provider_release === undefined
                        ? 'n/a'
                        : format(new Date(data.eol_date * 1000), 'Pp')}
                    </ValueWithLabel>
                    <ValueWithLabelEditButton
                      label="Cloud Connection ID"
                      onConfirm={() => setCloudConnectionIdDialogOpen(true)}
                    >
                      {data?.cloud_connection_id || 'n/a'}
                    </ValueWithLabelEditButton>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Cluster ID">
                      <CopyText
                        copyText={data.id}
                        copyButtonLabel="Copy Cluster ID"
                        successText="Cluster ID copied"
                        errorText="Cluster ID failed to copy"
                      />
                    </ValueWithLabel>
                    <ValueWithLabel label="Environment">
                      {data.environment || 'n/a'}
                    </ValueWithLabel>
                    <ValueWithLabel label="K8s Release">
                      {data.k8s_release_date === undefined
                        ? 'n/a'
                        : format(new Date(data.eol_date * 1000), 'Pp')}
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Status">
                      <ClusterBadgeStatus value={data.status} />
                    </ValueWithLabel>
                    <ValueWithLabel label="Version">
                      {data.version || 'n/a'}
                    </ValueWithLabel>
                    <ValueWithLabel label="EOL">
                      {data.eol_date === undefined
                        ? 'n/a'
                        : format(new Date(data.eol_date * 1000), 'Pp')}
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>
              ),
            },
            {
              id: 'cluster-status',
              label: 'Cluster Status',
              content: (
                <>
                  <Header
                    variant="h2"
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <ActionButton
                          key="reset-rescan"
                          onConfirm={async () => {
                            await updateCluster(data.account_id, data.id, {
                              rescan: 'completed',
                            });
                            await mutate();
                          }}
                        >
                          Reset Rescan Status
                        </ActionButton>
                      </SpaceBetween>
                    }
                  ></Header>
                  <ColumnLayout columns={3} variant="text-grid">
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Rescan Status">
                        {data.rescan}
                      </ValueWithLabel>
                      <ValueWithLabel label="Node Count">
                        {data.node_count}
                      </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Provider">
                        {data.cloud_provider || 'n/a'}
                      </ValueWithLabel>
                      <ValueWithLabel label="Managed Node Group Count">
                        {data.managed_ng_count}
                      </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                      <ValueWithLabel label="Region">
                        {data.region || 'n/a'}
                      </ValueWithLabel>
                      <ValueWithLabel label="Self-Managed Node Group Count">
                        {data.self_managed_ng_count}
                      </ValueWithLabel>
                    </SpaceBetween>
                  </ColumnLayout>
                </>
              ),
            },
            {
              id: 'chkk-agent',
              label: 'Chkk Agent',
              content: (
                <ColumnLayout columns={3} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Agent Version">
                      {data.chkk_metadata.version}
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Build">
                      {data.chkk_metadata.git_commit} (
                      {data.chkk_metadata.build_date})
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Namespace">
                      {data.chkk_metadata.namespace}
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>
              ),
            },
          ]}
        />
        <Container>
          <CreateNewLarStatusModal
            accountId={item.accountId}
            clusterId={item.clusterId}
            visible={createLarStatusDialogOpen}
            onDismiss={() => setCreateLarStatusDialogOpen(false)}
            onConfirm={async () => {
              await listLARMutate();
              setCreateLarStatusDialogOpen(false);
            }}
          />
          <FilterableTable<AdminLarStatus>
            data={
              listLARData?.data === undefined
                ? undefined
                : listLARData.data.map<AdminLarStatus>((a) => {
                    return {
                      rejected: a.rejected,
                      ...a.status,
                    };
                  })
            }
            selectionMode="single"
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween size="xs" direction="horizontal">
                    <Button
                      loading={loading}
                      disabled={!selectedItem}
                      onClick={async () => {
                        if (!selectedItem) {
                          return;
                        }
                        setLoading(true);

                        try {
                          await updateLARStatus(
                            item.accountId,
                            item.clusterId,
                            selectedItem?.lar_id,
                            {
                              rejected: !selectedItem?.rejected,
                            },
                          );
                          await listLARMutate();
                        } catch (e) {
                          console.error(e);
                        } finally {
                          setLoading(false);
                        }
                      }}
                    >
                      Reject
                    </Button>
                    <Button
                      loading={loading}
                      onClick={() => setCreateLarStatusDialogOpen(true)}
                    >
                      Create new
                    </Button>
                  </SpaceBetween>
                }
              >
                LAR Status
              </Header>
            }
            columns={[
              {
                id: 'lar_id',
                label: 'LAR',
                cell: (item) => item.lar_id,
                defaultVisible: true,
                sortingField: 'lar_id',
                filterOperators: ['=', '!='],
              },
              {
                id: 'acknowledge',
                label: 'Acknowledged',
                cell: (item) => (item.acknowledge ? 'yes' : 'no'),
                defaultVisible: true,
                sortingField: 'acknowledge',
                filterOperators: ['=', '!='],
              },
              {
                id: 'ignore',
                label: 'Ignored',
                cell: (item) => (item.ignore ? 'yes' : 'no'),
                defaultVisible: true,
                sortingField: 'ignore',
                filterOperators: ['=', '!='],
              },
              {
                id: 'ignore_status',
                label: 'Ignore Status',
                cell: (item) =>
                  item.ignore_status === undefined
                    ? '-'
                    : `${item.ignore_status.reason || ''} (${
                        item.ignore_status.comment || '-'
                      })`,
                defaultVisible: true,
                sortingField: 'ignore_status',
                filterOperators: ['=', '!='],
              },
              {
                id: 'false_positive_status',
                label: 'False Positive',
                cell: (item) => item.false_positive_status || 'no',
                defaultVisible: true,
                sortingField: 'false_positive_status',
                filterOperators: ['=', '!='],
              },
              {
                id: 'rejected',
                label: 'Rejected',
                cell: (item) => (item.rejected ? 'yes' : 'no'),
                defaultVisible: true,
                sortingField: 'rejected',
                filterOperators: ['=', '!='],
              },
              {
                id: 'jira_ticket',
                label: 'Jira Ticket',
                cell: (item) => item.jira_ticket || '-',
                defaultVisible: false,
                sortingField: 'jira_ticket',
                filterOperators: ['=', '!='],
              },
            ]}
            selectionTrackBy={(lar) =>
              `${item.accountId}-${item.clusterId}-${lar.lar_id}`
            }
            onSelectionChange={(event) => {
              if (event.detail.selectedItems.length > 0) {
                setSelectedItem(event.detail.selectedItems[0]);
              } else {
                setSelectedItem(undefined);
              }
            }}
          />
        </Container>
      </SpaceBetween>
    </SplitPanel>
  );
}

function ProcessingDialogModal(props: {
  header: string;
  onConfirm: () => Promise<void>;
  onDismiss: () => void;
  visible: boolean;
  children: React.ReactNode;
}): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const onConfirmClick = async () => {
    setProcessing(true);
    try {
      await props.onConfirm();
    } catch (err: unknown) {
      console.error(err);
    }
    setProcessing(false);
    props.onDismiss();
  };

  return (
    <Modal
      header={props.header}
      onDismiss={props.onDismiss}
      visible={props.visible}
    >
      <SpaceBetween direction="vertical" size="m">
        {props.children}
        <SpaceBetween direction="horizontal" size="xs">
          <ActionButton
            key="confirm"
            onConfirm={onConfirmClick}
            disabled={processing}
          >
            Confirm
          </ActionButton>
          <ActionButton key="cancel" onClick={props.onDismiss}>
            Cancel
          </ActionButton>
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
}

function CloudProviderClusterNameEditor(props: {
  accountId: string;
  clusterId: string;
  cloudProviderClusterName: string;
  onDismiss: () => void;
  visible?: boolean;
}): JSX.Element {
  const [cloudProviderClusterName, setCloudProviderClusterName] = useState(
    props.cloudProviderClusterName,
  );

  useEffect(() => {
    setCloudProviderClusterName(props.cloudProviderClusterName);
  }, [props.cloudProviderClusterName, props.visible]);

  return (
    <ProcessingDialogModal
      header="Edit Cloud Provider Cluster Name"
      onConfirm={async () => {
        await updateCluster(props.accountId, props.clusterId, {
          cloud_provider_cluster_name: cloudProviderClusterName,
        });
      }}
      onDismiss={props.onDismiss}
      visible={props.visible || false}
    >
      <FormField label="Cloud Provider Cluster Name">
        <Input
          value={cloudProviderClusterName}
          onChange={({ detail }) => setCloudProviderClusterName(detail.value)}
        />
      </FormField>
    </ProcessingDialogModal>
  );
}

function CloudConnectionIdEditor(props: {
  accountId: string;
  clusterId: string;
  cloudConnectionId: string;
  onDismiss: () => void;
  visible?: boolean;
}): JSX.Element {
  // call list cloud connection id api
  const { data, mutate } = useListCloudConnections(props.accountId);
  const [cloudConnection, setCloudConnection] = useState<{
    label?: string;
    value?: string;
  }>({});

  useEffect(() => {
    setCloudConnection({
      label: props.cloudConnectionId,
      value: props.cloudConnectionId,
    });
  }, [props.cloudConnectionId, props.visible]);

  return (
    <ProcessingDialogModal
      header="Edit Cloud Connection ID"
      onConfirm={async () => {
        await updateCluster(props.accountId, props.clusterId, {
          cloud_connection_id: cloudConnection.value,
        });
      }}
      onDismiss={props.onDismiss}
      visible={props.visible || false}
    >
      <FormField label="Cloud Connection ID">
        <Select
          selectedOption={cloudConnection}
          onChange={({ detail }) =>
            setCloudConnection({
              label: detail.selectedOption.label as string,
              value: detail.selectedOption.value as string,
            })
          }
          options={data?.data.map((item) => ({
            label: item.id,
            value: item.id,
          }))}
        ></Select>
      </FormField>
    </ProcessingDialogModal>
  );
}
