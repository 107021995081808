import { useListCloudConnections } from 'api/admin';
import { CloudConnection } from 'api/admin-models';
import { useSplitPanel } from 'contexts/split-panel';
import { FilterableTable } from 'components/tables/filterable-table';
import { CloudConnectionDetails } from './cloud_connections_details';

export function AccountCloudConnections(props: {
  orgId: string;
  accountId: string;
}): JSX.Element {
  const updateSplitPanel = useSplitPanel();
  const { data } = useListCloudConnections(props.accountId, {
    page_size: 1000,
  });

  return (
    <FilterableTable<CloudConnection>
      selectionMode="single"
      data={data?.data}
      columns={[
        {
          id: 'id',
          label: 'Cloud Connection ID',
          cell: (item) => item.id,

          defaultVisible: false,
        },
        {
          id: 'cloud_type',
          label: 'Cloud Type',
          cell: (item) => item.cloud_type,

          defaultVisible: true,
          sortingField: 'cloud_type',
          filterOperators: ['=', '!=', ':', '!:'],
          filterOptions: ['AWS', 'GCP'],
        },
        {
          id: 'account_id',
          label: 'Account ID',
          cell: (item) =>
            item.aws?.aws_account_id || item.gcp?.project_id || '-',

          defaultVisible: true,
        },
        {
          id: 'status',
          label: 'Status',
          cell: (item) => item.status,

          defaultVisible: true,

          sortingField: 'status',
          filterOperators: ['=', '!=', ':', '!:'],
        },
      ]}
      selectionTrackBy={(item) => `${item.id}`}
      onSelectionChange={(event) => {
        updateSplitPanel(
          <CloudConnectionDetails
            cloudConnection={{
              accountId: props.accountId,
              cloudConnectionId: event.detail.selectedItems[0].id,
            }}
          />,
        );
      }}
    />
  );
}
