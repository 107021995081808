import { Key, SWRConfiguration } from 'swr';
import useSwrInfinite from 'swr/infinite';
import { ListTablesParams } from './analytics-models';
import { getListTablesKey, listTables } from './analytics';

export const useListTablesInfinite = <TError = unknown>(
  params?: ListTablesParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listTables>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = (
    pageIndex: number,
    previousPageData: Awaited<ReturnType<typeof listTables>> | null,
  ) => {
    if (!isEnabled) return null;

    // reached the end
    if (previousPageData && !previousPageData.continuation_token) return null;

    if (previousPageData === null) return getListTablesKey(params);

    // add the cursor to the API endpoint
    return getListTablesKey({
      ...params,
      continuation_token: previousPageData.continuation_token,
    });
  };
  const swrFn = (args: [string, ListTablesParams]) =>
    listTables({ page_size: 100, ...args[1] });

  const query = useSwrInfinite<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    {
      revalidateFirstPage: false,
      persistSize: true,
      initialSize: 100000,
    },
  );

  return {
    swrKey,
    ...query,
  };
};
