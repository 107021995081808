import { useState } from 'react';

export function useSplitPanel() {
  const [splitPanelSize, setSplitPanelSize] = useState(500);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [hasManuallyClosedOnce, setHasManuallyClosedOnce] = useState(false);

  const onSplitPanelResize = function (event: { detail: { size: number } }) {
    setSplitPanelSize(event.detail.size);
  };

  const onSplitPanelToggle = function (event: { detail: { open: boolean } }) {
    setSplitPanelOpen(event.detail.open);

    if (!open) {
      setHasManuallyClosedOnce(true);
    }
  };

  const [splitPanelContent, setSplitPanelContent] = useState<React.ReactNode>(
    () => <></>,
  );

  const openSplitPanel = function () {
    if (!hasManuallyClosedOnce) {
      setSplitPanelOpen(true);
    }
  };

  return {
    splitPanelOpen,
    onSplitPanelToggle,
    splitPanelSize,
    onSplitPanelResize,
    splitPanelContent,
    setSplitPanelContent,
    openSplitPanel,
  };
}
