import {
  Button,
  Container,
  ContentLayout,
  ExpandableSection,
  Header,
  Select,
  SpaceBetween,
  Spinner,
} from '@cloudscape-design/components';
import {
  createQueryExecution,
  updateQuery,
  useListQueries,
  useListQueryExecutions,
} from 'api/analytics';
import { Query, QueryExecution, QueryVariable } from 'api/analytics-models';
import axios from 'axios';
import ProcessingButton from 'components/processing-button';
import { useNotifications } from 'contexts/notification';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CreateQueryModal from './create_modal';
import { QueryEditor } from './editor';
import ResultsDisplay from './results';
import QueryVariablesTable from './variables';

export function MidasQueriesIndex(): JSX.Element {
  const addNotification = useNotifications();
  const [createEditModalMode, setCreateEditModalMode] = useState<
    'create' | 'edit' | undefined
  >(undefined);
  const [currentQuery, setCurrentQuery] = useState<Query | undefined>(
    undefined,
  );
  const [code, setCode] = useState<string>('');
  const [variables, setVariables] = useState<QueryVariable[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [executionsRevalidateInterval, setExecutionsRevalidateInterval] =
    useState<number>(0);

  const [urlSearchParams, setUrlSearchParams] = useSearchParams({
    show: 'mine',
  });
  const { data: queryList, mutate: queryListMutate } = useListQueries({
    shared: urlSearchParams.get('show') !== 'mine',
  });
  const { data: executionsList, mutate: executionsListMutate } =
    useListQueryExecutions(
      currentQuery?.id || '',
      { page_size: 5, resolve_athena: true },
      {
        swr: {
          enabled: currentQuery !== undefined,
          refreshInterval: executionsRevalidateInterval,
        },
      },
    );

  useEffect(() => {
    if (
      executionsList?.data?.[0]?.result?.status === 'succeeded' ||
      executionsList?.data?.[0]?.result?.status === 'failed'
    ) {
      setExecutionsRevalidateInterval(0);
    }
  }, [executionsList]);

  const updateCurrentQuery = (query: Query) => {
    setCurrentQuery(query);
    setCode(query.query || '');
    setVariables(query.variables || []);
    void executionsListMutate();
  };

  useEffect(() => {
    if (urlSearchParams.get('id') !== undefined && queryList) {
      const query = queryList.data?.find(
        (q) => q.id === urlSearchParams.get('id'),
      );
      if (query) {
        updateCurrentQuery(query);
      }
    }
  }, [queryList, urlSearchParams]);

  const pageTitlePrefix =
    urlSearchParams.get('show') === 'mine' ? 'My' : 'Shared';

  const latestExecution: QueryExecution | undefined = executionsList
    ? executionsList.data?.[0]
    : undefined;

  if (queryList === undefined) {
    return (
      <ContentLayout
        header={<Header variant="h1">{pageTitlePrefix} Midas Queries</Header>}
      >
        <Container>
          <SpaceBetween size="m" direction="horizontal" alignItems="center">
            <Spinner size="large" /> Loading...
          </SpaceBetween>
        </Container>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout
      header={<Header variant="h1">{pageTitlePrefix} Midas Queries</Header>}
    >
      <CreateQueryModal
        mode={createEditModalMode || 'create'}
        open={createEditModalMode !== undefined}
        query={currentQuery}
        onDismiss={() => setCreateEditModalMode(undefined)}
        onSubmit={async () => {
          await queryListMutate();
          setCurrentQuery((priorCurrentQuery) =>
            priorCurrentQuery === undefined
              ? undefined
              : queryList.data?.find((q) => q.id === priorCurrentQuery.id),
          );
          setCreateEditModalMode(undefined);
        }}
      />
      <SpaceBetween size="m" direction="vertical">
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  {urlSearchParams.get('show') === 'mine' && (
                    <Button
                      key={'create'}
                      variant="primary"
                      iconName="add-plus"
                      onClick={() => setCreateEditModalMode('create')}
                    >
                      New Query
                    </Button>
                  )}
                  {urlSearchParams.get('show') === 'mine' && (
                    <Button
                      key={'edit'}
                      variant="normal"
                      onClick={() => setCreateEditModalMode('edit')}
                      disabled={currentQuery === undefined}
                    >
                      Rename
                    </Button>
                  )}
                  {urlSearchParams.get('show') !== 'mine' && (
                    <Button
                      key={'run'}
                      variant="primary"
                      iconName="refresh"
                      loading={saving}
                      disabled={currentQuery === undefined}
                      onClick={async () => {
                        setSaving(true);
                        try {
                          if (currentQuery) {
                            await createQueryExecution(currentQuery.id, {
                              variables: variables.map((v) => ({
                                name: v.name,
                                value: v.localValue || '',
                              })),
                            });
                            await executionsListMutate();
                            setExecutionsRevalidateInterval(1000);
                          }
                        } catch (error: unknown) {
                          if (axios.isAxiosError(error)) {
                            addNotification(
                              'Failed to run query',
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                              (error.response?.data as { message: string })
                                .message || error.message,
                              'error',
                            );
                          } else {
                            addNotification(
                              'Failed to run query',
                              JSON.stringify(error),
                              'error',
                            );
                          }
                        } finally {
                          setSaving(false);
                        }
                      }}
                    >
                      Run
                    </Button>
                  )}
                </SpaceBetween>
              }
            >
              Query
            </Header>
          }
        >
          <Select
            options={(queryList?.data || []).map((q) => ({
              value: q.id,
              label: q.name,
            }))}
            selectedOption={
              currentQuery
                ? { value: currentQuery.id, label: currentQuery.name }
                : null
            }
            onChange={({ detail }) => {
              const newCurrentQuery = queryList?.data?.find(
                (q) => q.id === detail.selectedOption?.value,
              );
              if (newCurrentQuery) {
                updateCurrentQuery(newCurrentQuery);

                urlSearchParams.set('id', detail.selectedOption.value || '');
                setUrlSearchParams(urlSearchParams);
              }
            }}
            filteringType={'auto'}
          />
        </Container>
        {urlSearchParams.get('show') == 'mine' && (
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween size="s" direction="horizontal">
                    <Button
                      variant="primary"
                      iconName="refresh"
                      disabled={currentQuery === undefined}
                      loading={saving}
                      onClick={async () => {
                        setSaving(true);
                        try {
                          if (currentQuery) {
                            await updateQuery(currentQuery.id, {
                              query: code,
                              variables: variables,
                            });
                            await queryListMutate();
                            await createQueryExecution(currentQuery.id, {
                              variables: variables.map((v) => ({
                                name: v.name,
                                value: v.localValue || '',
                              })),
                            });
                            await executionsListMutate();
                            setExecutionsRevalidateInterval(1000);
                          }
                        } catch (error: unknown) {
                          if (axios.isAxiosError(error)) {
                            addNotification(
                              'Failed to run query',
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                              (error.response?.data as { message: string })
                                .message || error.message,
                              'error',
                            );
                          } else {
                            addNotification(
                              'Failed to run query',
                              JSON.stringify(error),
                              'error',
                            );
                          }
                        } finally {
                          setSaving(false);
                        }
                      }}
                    >
                      Run
                    </Button>
                    <Button
                      iconName="file"
                      disabled={currentQuery === undefined}
                      loading={saving}
                      onClick={async () => {
                        setSaving(true);
                        try {
                          if (currentQuery) {
                            await updateQuery(currentQuery.id, {
                              query: code,
                              variables: variables,
                            });
                            await queryListMutate();
                          }
                        } finally {
                          setSaving(false);
                        }
                      }}
                    >
                      Save
                    </Button>
                    <ProcessingButton
                      iconName={currentQuery?.shared ? 'star-filled' : 'star'}
                      disabled={currentQuery === undefined}
                      onClick={async () => {
                        if (currentQuery) {
                          await updateQuery(currentQuery.id, {
                            shared: !currentQuery.shared,
                          });
                          await queryListMutate();
                        }
                      }}
                    >
                      Share
                    </ProcessingButton>
                  </SpaceBetween>
                }
              >
                Code
              </Header>
            }
            footer={
              <ExpandableSection headerText="Variables" variant="footer">
                <QueryVariablesTable
                  variant="embedded"
                  loading={currentQuery === undefined || saving}
                  editable={'all'}
                  items={variables}
                  setItems={setVariables}
                />
              </ExpandableSection>
            }
          >
            <QueryEditor
              code={code}
              onDelayedChange={(newCode) => setCode(newCode)}
              loading={false}
            />
          </Container>
        )}
        {urlSearchParams.get('show') !== 'mine' && (
          <QueryVariablesTable
            title={'Variables'}
            loading={currentQuery === undefined || saving}
            editable={'local-value'}
            items={variables}
            setItems={setVariables}
          />
        )}
        <ResultsDisplay
          latestExecution={latestExecution}
          loading={executionsRevalidateInterval > 0}
        />
      </SpaceBetween>
    </ContentLayout>
  );
}
