import { Box, Link, Button } from '@cloudscape-design/components';

export function ValueWithLabel({
  label,
  children,
  externalLink,
}: {
  label: string;
  children: React.ReactNode;
  externalLink?: string;
}): JSX.Element {
  return (
    <div>
      <Box variant="awsui-key-label">
        {label}
        {externalLink && (
          <>
            &nbsp;
            <Link external href={externalLink} />
          </>
        )}
      </Box>
      <div>{children}</div>
    </div>
  );
}

export function ValueWithLabelEditButton({
  label,
  children,
  onConfirm,
}: {
  label: string;
  children: React.ReactNode;
  externalLink?: string;
  onConfirm: () => void;
}): JSX.Element {
  return (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>
        {children}
        <Button
          onClick={() => onConfirm()}
          variant="icon"
          iconAlign="right"
          iconName="edit"
        />
      </div>
    </div>
  );
}
