/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Chkk Admin API v1.0
 * Chkk Admin API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

/**
 * The type of action
 */
export type ActionType = (typeof ActionType)[keyof typeof ActionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionType = {
  VERIFY: 'VERIFY',
} as const;
