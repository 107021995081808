import { API } from 'aws-amplify';

interface customInstanceProperties {
  url: string;
  method: 'get' | 'post' | 'put' | 'delete' | 'patch';
  params?: Record<string, string | number | string[]>;
  headers?: Record<string, string>;
  data?: unknown;
  responseType?: string;
}

const customInstance = async <T>(
  apiName: string,
  { url, method, params, headers, data }: customInstanceProperties,
): Promise<T> => {
  if (method === 'get') {
    return (await API.get(
      apiName,
      url +
        '?' +
        new URLSearchParams(
          params as Record<string, string> | undefined,
        ).toString(),
      { headers },
    )) as T;
  } else if (method === 'post') {
    return (await API.post(
      apiName,
      url +
        '?' +
        new URLSearchParams(
          params as Record<string, string> | undefined,
        ).toString(),
      {
        body: data,
        headers: headers,
      },
    )) as T;
  } else if (method === 'put') {
    return (await API.put(
      apiName,
      url +
        '?' +
        new URLSearchParams(
          params as Record<string, string> | undefined,
        ).toString(),
      {
        body: data,
        headers: headers,
      },
    )) as T;
  } else if (method === 'patch') {
    return (await API.patch(
      apiName,
      url +
        '?' +
        new URLSearchParams(
          params as Record<string, string> | undefined,
        ).toString(),
      {
        body: data,
        headers: headers,
      },
    )) as T;
  } else if (method === 'delete') {
    return (await API.del(
      apiName,
      url +
        new URLSearchParams(
          params as Record<string, string> | undefined,
        ).toString(),
      { headers },
    )) as T;
  }

  throw Error(`Unsupported method ${method}`);
};

export const customAdminApiInstance = async <T>(
  props: customInstanceProperties,
): Promise<T> => {
  return customInstance<T>('admin-api', props);
};

export const customAnalyticsApiInstance = async <T>(
  props: customInstanceProperties,
): Promise<T> => {
  return customInstance<T>('analytics-api', props);
};
