/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Chkk Admin API v1.0
 * Chkk Admin API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

/**
 * The type of subscription aka how it is managed and charged
 */
export type SubscriptionType =
  (typeof SubscriptionType)[keyof typeof SubscriptionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionType = {
  CommunityEdition: 'CommunityEdition',
  Internal: 'Internal',
  AWSMarketplace: 'AWSMarketplace',
} as const;
