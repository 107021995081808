import {
  Box,
  Button,
  ButtonDropdown,
  CodeEditor,
  Flashbar,
  FlashbarProps,
  Modal,
  SpaceBetween,
} from '@cloudscape-design/components';
import * as ace from 'ace-builds';
import 'ace-builds/css/ace.css';
import 'ace-builds/src-noconflict/ace';
import extLanguageToolsUrl from 'ace-builds/src-noconflict/ext-language_tools?url';
import jsonModeUrl from 'ace-builds/src-noconflict/mode-json?url';
import monokaiThemeUrl from 'ace-builds/src-noconflict/theme-monokai?url';
import tomorrowNightBrighThemeUrl from 'ace-builds/src-noconflict/theme-tomorrow_night_bright?url';
import jsonWorkerUrl from 'ace-builds/src-noconflict/worker-json?url';
import { useEffect, useState } from 'react';
import { ARGOCD_PLAN_STAGES } from 'src/data/template_upgrade_stages';
import { codeEditorAriaLabels } from 'src/i18n/code-editor';
import Ajv from 'ajv-draft-04';
import { parse } from 'json-source-map';
import schema from 'src/api/upgrade.json';

import {
  createUpgradeTemplateStages,
  updateUpgradeTemplateStages,
  useListUpgradeTemplateStages,
} from 'api/admin';

import { EKS_124_125_INPLACE_PLAN_STAGES } from 'data/template_eks_update_stages';
import { EMPTY_PLAN_STAGES } from 'data/template_empty_stages';
import { UpgradeStage } from 'api/admin-models';

interface ErrorResponse {
  message: string;
  response: { data: object };
}

function instanceOfErrorResponse(v: object): v is ErrorResponse {
  return 'message' in v;
}

function validate(code: string): string[] {
  // basic parsing validation
  let data: object | null = null;
  try {
    data = JSON.parse(code) as object;
  } catch (err) {
    return ['invalid JSON'];
  }

  // generate json source map
  const map = parse(code);

  // JSON schema validation
  const ajv = new Ajv({ strict: false, allErrors: true });
  if (!ajv.validate(schema, data)) {
    const errors = ajv.errors || [];
    const errorsSeen = new Set<string>();

    const niceErrors = errors
      .map((error) => {
        if (!errorsSeen.has(error.instancePath)) {
          errorsSeen.add(error.instancePath);
          const location = map.pointers[error.instancePath];
          const errorLoc = location.value || location.key;
          return `\t- ${error.message || ''} (Line ${errorLoc.line + 1} | ${
            error.instancePath
          })`;
        } else {
          return undefined;
        }
      })
      .filter((e) => e !== undefined);
    return [
      `Found ${niceErrors.length} schema errors: \n${niceErrors.join('\n')}`,
    ];
  }

  return [];
}

export interface UpgradeTemplateEditorProps {
  readonly mode: 'create' | 'edit';
  readonly accountId: string;
  readonly upgradeId: string;

  readonly visible: boolean;

  readonly onCancel: () => void;
  readonly onConfirm: () => void;
}

export function UpgradeTemplateEditor(props: UpgradeTemplateEditorProps): JSX.Element {
  const [preferences, setPreferences] = useState({});
  const [flashbarItems, setFlashbarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [processing, setProcessing] = useState(false);
  const { data, mutate } = useListUpgradeTemplateStages(
    props.accountId,
    props.upgradeId,
    {},
  );

  const [updatedCode, setUpdatedCode] = useState('');

  useEffect(() => {
    if (props.visible) {
      let code = '';
      if (props.mode === 'edit' && data !== undefined) {
        code = JSON.stringify(data.data, undefined, 2);
      } else if (props.mode === 'create') {
        code = JSON.stringify(EMPTY_PLAN_STAGES, undefined, 2);
      }

      setUpdatedCode(code);
    }
  }, [props.visible]);

  useEffect(() => {
    ace.config.setModuleUrl('ace/mode/json', jsonModeUrl);
    ace.config.setModuleUrl('ace/mode/json_worker', jsonWorkerUrl);
    ace.config.setModuleUrl('ace/theme/monokai', monokaiThemeUrl);
    ace.config.setModuleUrl(
      'ace/theme/tomorrow_night_bright',
      tomorrowNightBrighThemeUrl,
    );
    ace.config.setModuleUrl('ace/ext/language_tools', extLanguageToolsUrl);
  });

  const onValidate = () => {
    const errors = validate(updatedCode);
    if (errors.length > 0) {
      setFlashbarItems(
        errors.map((error, idx) => ({
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss',
          onDismiss: () => setFlashbarItems([]),
          content: (
            <div style={{ whiteSpace: 'pre-wrap' }}>
              Upgrade Template Validation failed: {error}
            </div>
          ),
          id: `json_error_${idx}`,
        })),
      );
    } else {
      setFlashbarItems([
        {
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss',
          onDismiss: () => setFlashbarItems([]),
          content: <>No Validation Issues found</>,
        },
      ]);
    }
  };

  const onOkClick = async () => {
    setProcessing(true);
    // validate the text - basic
    const errors = validate(updatedCode);
    if (errors.length > 0) {
      setFlashbarItems(
        errors.map((error) => ({
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss',
          onDismiss: () => setFlashbarItems([]),
          content: <>Upgrade Template Validation failed: {error}</>,
          id: 'json_error',
        })),
      );
      setProcessing(false);
      return;
    }

    // store the update
    if (props.mode === 'create') {
      const data = JSON.parse(updatedCode) as UpgradeStage[];
      try {
        await createUpgradeTemplateStages(props.accountId, props.upgradeId, data);
        await mutate();
        setProcessing(false);
        props.onConfirm();
      } catch (err: unknown) {
        if (
          err !== null &&
          typeof err === 'object' &&
          instanceOfErrorResponse(err)
        ) {
          setFlashbarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss',
              onDismiss: () => setFlashbarItems([]),
              content: (
                <>
                  Failed to create upgrade template: {err.message.toString()} -{' '}
                  {JSON.stringify(err.response.data)}
                </>
              ),
              id: 'update_error',
            },
          ]);
        }
        setProcessing(false);
        return;
      }
    } else {
      const data = JSON.parse(updatedCode) as UpgradeStage[];
      try {
        await updateUpgradeTemplateStages(props.accountId, props.upgradeId, data);
        await mutate();
        setProcessing(false);
        props.onConfirm();
      } catch (err: unknown) {
        if (
          err !== null &&
          typeof err === 'object' &&
          instanceOfErrorResponse(err)
        ) {
          setFlashbarItems([
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss',
              onDismiss: () => setFlashbarItems([]),
              content: (
                <>
                  Failed to update upgrade template: {err.message.toString()} -{' '}
                  {JSON.stringify(err.response.data)}
                </>
              ),
              id: 'update_error',
            },
          ]);
        }
        setProcessing(false);
        return;
      }
    }
  };

  return (
    <Modal
      onDismiss={() => props.onCancel()}
      visible={props.visible}
      closeAriaLabel="Close modal"
      size="max"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <ButtonDropdown
              disabled={processing}
              items={[
                {
                  text: 'Addon',
                  items: [{ text: 'ArgoCD Upgrade', id: 'argocd' }],
                },
                {
                  text: 'Control Plane',
                  items: [
                    {
                      text: 'EKS 1.24 -> 1.25 in-place',
                      id: 'eks-124-125-in-place',
                    },
                  ],
                },
              ]}
              onItemClick={(details) => {
                if (details.detail.id === 'argocd') {
                  setUpdatedCode(
                    JSON.stringify(ARGOCD_PLAN_STAGES, undefined, 2),
                  );
                } else if (details.detail.id === 'eks-124-125-in-place') {
                  setUpdatedCode(
                    JSON.stringify(
                      EKS_124_125_INPLACE_PLAN_STAGES,
                      undefined,
                      2,
                    ),
                  );
                }
              }}
            >
              Load Template
            </ButtonDropdown>
            <Button
              disabled={processing}
              onClick={() => onValidate()}
              variant="link"
            >
              Validate
            </Button>
            <Button
              disabled={processing}
              onClick={() => props.onCancel()}
              variant="link"
            >
              Cancel
            </Button>
            <Button
              loading={processing}
              onClick={() => void onOkClick()}
              variant="primary"
            >
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={`Edit Upgrade Template ${props.upgradeId}`}
    >
      <SpaceBetween direction="vertical" size="m">
        <Flashbar items={flashbarItems} />
        <CodeEditor
          ace={ace}
          value={updatedCode}
          onDelayedChange={(event) => setUpdatedCode(event.detail.value)}
          language="json"
          preferences={preferences}
          onPreferencesChange={(event) => setPreferences(event.detail)}
          loading={(data === undefined && props.mode === 'edit') || processing}
          i18nStrings={codeEditorAriaLabels}
          themes={{ light: [], dark: ['monokai', 'tomorrow_night_bright'] }}
        />
      </SpaceBetween>
    </Modal>
  );
}
