import {
  Alert,
  Badge,
  Container,
  Header,
  SpaceBetween,
  Spinner,
  StatusIndicator,
  StatusIndicatorProps,
} from '@cloudscape-design/components';
import { FilterableTable } from 'components/tables/filterable-table';
import { usePapaParse } from 'react-papaparse';
import { useEffect, useState } from 'react';
import {
  QueryExecution,
  QueryExecutionResultStatus,
} from 'api/analytics-models';

function getQueryExecutionStatus(
  status: QueryExecutionResultStatus,
): StatusIndicatorProps.Type {
  switch (status) {
    case 'pending':
      return 'pending';
    case 'running':
      return 'in-progress';
    case 'failed':
      return 'error';
    case 'succeeded':
      return 'success';
  }
}

export default function ResultsDisplay(props: {
  latestExecution: QueryExecution | undefined;
  loading: boolean;
}) {
  const { readRemoteFile } = usePapaParse();
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<{ [key: string]: unknown }[] | undefined>(
    undefined,
  );

  useEffect(() => {
    setData(undefined);
    setError(undefined);

    if (props.latestExecution?.result?.data_url === undefined) {
      return;
    }

    readRemoteFile<{ [key: string]: unknown }>(
      props.latestExecution?.result?.data_url || '',
      {
        header: true,
        preview: 10000,
        worker: true,
        download: true,
        error: (err) => {
          setError(err);
        },
        chunk: (results) => {
          setData((prior) =>
            prior ? [...prior, ...results.data] : results.data,
          );
        },
        complete: () => {
          // dummy
        },
      },
    );
  }, [props.latestExecution]);

  return (
    <Container
      header={
        <>
          {props.latestExecution === undefined ? (
            <Header variant="h2" counter="(never run)">
              Results
            </Header>
          ) : (
            <Header
              variant="h2"
              counter={
                data === undefined
                  ? `(${props.latestExecution.result?.status || 'n/a'})`
                  : `(${data.length})`
              }
              info={
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <StatusIndicator
                    type={getQueryExecutionStatus(
                      props.latestExecution.result?.status || 'pending',
                    )}
                  />
                  {props.loading && <Spinner />}
                </div>
              }
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  <Badge>
                    Run Time:{' '}
                    {props.latestExecution.statistics?.total_execution_time ||
                      'n/a'}
                  </Badge>
                  <Badge>
                    Data Scanned:{' '}
                    {props.latestExecution.statistics?.data_scanned ===
                      undefined
                      ? 'n/a'
                      : props.latestExecution.statistics?.data_scanned}
                  </Badge>
                </SpaceBetween>
              }
            >
              Results
            </Header>
          )}
        </>
      }
    >
      {props.latestExecution?.result?.status === 'succeeded' && (
        <>
          {error && <Alert type="error">Error: {JSON.stringify(error)}</Alert>}
          {!error && data === undefined && <Spinner size="large" />}
          {!error && data !== undefined && data.length === 0 && (
            <Alert type="info">No results</Alert>
          )}
          {!error && data !== undefined && data.length > 0 && (
            <FilterableTable<{ [key: string]: unknown }>
              selectionMode="none"
              columns={Object.keys(data[0]).map((k) => ({
                id: k,
                label: k,
                cell: (val) => val[k] as string,
                defaultVisible: true,

                sortingField: k,
                filterOperators: ['=', '!=', ':', '!:'],
              }))}
              selectionTrackBy={(val) => Object.values(val)[0] as string}
              data={data}
            />
          )}
        </>
      )}
      {props.latestExecution?.result?.status === 'failed' && (
        <Alert type="error" header="Query failed">
          {props.latestExecution?.result?.error || 'Error message unavailable'}
        </Alert>
      )}
    </Container>
  );
}
