/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Chkk Admin API v1.0
 * Chkk Admin API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */
import useSwr from 'swr';
import type { SWRConfiguration, Key } from 'swr';
import type {
  ListOrganizationsResponse,
  ListOrganizationsParams,
  Organization,
  ListSubscriptionsResponse,
  ListSubscriptionsParams,
  CreateSubscriptionRequest,
  AdminSubscription,
  UpdateSubscriptionRequest,
  ListAccountsResponse,
  ListAccountsParams,
  Account,
  ListTeamsResponse,
  ListTeamsParams,
  Team,
  ListTeamMembersResponse,
  ListTeamMembersParams,
  GetTeamMemberImpersonationTokenResponse,
  ListIntegrationsResponse,
  ListIntegrationsParams,
  Integration,
  ListAddonInstancesResponse,
  ListAddonInstancesParams,
  AddonInstance,
  ListClustersResponse,
  ListClustersParams,
  Cluster,
  UpdateClusterRequest,
  ListLARStatusResponse,
  ListLARStatusParams,
  LARStatus,
  UpdateLARStatusRequest,
  ListCloudConnectionsResponse,
  ListCloudConnectionsParams,
  CloudConnection,
  CloudConnectionAction,
  CreateCloudConnectionActionRequest,
  ListUpgradePlansResponse,
  ListUpgradesParams,
  UpgradePlanAdminView,
  UpdateUpgradePlanRequest,
  ListUpgradePlanStagesResponse,
  CreateUpgradePlanStagesRequest,
  UpdateUpgradePlanStagesRequest,
  GlobalListUpgradesParams,
  ListUpgradeTemplatesResponse,
  ListUpgradeTemplatesParams,
  UpgradeTemplateAdminView,
  UpdateUpgradeTemplateRequest,
  ListUpgradeTemplateStagesResponse,
  CreateUpgradeTemplateStagesRequest,
  UpdateUpgradeTemplateStagesRequest,
  GlobalListUpgradeTemplatesParams,
  ListAddonResponse,
  ListAddonsParams,
  Addon,
  AddonImageDetails,
  ListUserResponse,
  ListUsersParams,
  User,
  UserEngagement,
} from './admin-models';
import { customAdminApiInstance } from './custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List organizations
 */
export const listOrganizations = (params?: ListOrganizationsParams) => {
  return customAdminApiInstance<ListOrganizationsResponse>({
    url: `/organizations`,
    method: 'get',
    params,
  });
};

export const getListOrganizationsKey = (params?: ListOrganizationsParams) =>
  [`/organizations`, ...(params ? [params] : [])] as const;

export type ListOrganizationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listOrganizations>>
>;
export type ListOrganizationsQueryError = unknown;

export const useListOrganizations = <TError = unknown>(
  params?: ListOrganizationsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listOrganizations>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListOrganizationsKey(params) : null));
  const swrFn = () => listOrganizations(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get details of a specific organization
 */
export const getOrganization = (organizationId: string) => {
  return customAdminApiInstance<Organization>({
    url: `/organizations/${organizationId}`,
    method: 'get',
  });
};

export const getGetOrganizationKey = (organizationId: string) =>
  [`/organizations/${organizationId}`] as const;

export type GetOrganizationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrganization>>
>;
export type GetOrganizationQueryError = unknown;

export const useGetOrganization = <TError = unknown>(
  organizationId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getOrganization>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!organizationId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetOrganizationKey(organizationId) : null));
  const swrFn = () => getOrganization(organizationId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List Subscriptions in an organization
 */
export const listSubscriptions = (
  organizationId: string,
  params?: ListSubscriptionsParams,
) => {
  return customAdminApiInstance<ListSubscriptionsResponse>({
    url: `/organizations/${organizationId}/subscriptions`,
    method: 'get',
    params,
  });
};

export const getListSubscriptionsKey = (
  organizationId: string,
  params?: ListSubscriptionsParams,
) =>
  [
    `/organizations/${organizationId}/subscriptions`,
    ...(params ? [params] : []),
  ] as const;

export type ListSubscriptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listSubscriptions>>
>;
export type ListSubscriptionsQueryError = unknown;

export const useListSubscriptions = <TError = unknown>(
  organizationId: string,
  params?: ListSubscriptionsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listSubscriptions>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!organizationId;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getListSubscriptionsKey(organizationId, params) : null);
  const swrFn = () => listSubscriptions(organizationId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Create an internal subscription in an organization.
 */
export const createSubscription = (
  organizationId: string,
  createSubscriptionRequest: CreateSubscriptionRequest,
) => {
  return customAdminApiInstance<void>({
    url: `/organizations/${organizationId}/subscriptions`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createSubscriptionRequest,
  });
};

/**
 * Get a subscription in an account
 */
export const getSubscription = (
  organizationId: string,
  subscriptionId: string,
) => {
  return customAdminApiInstance<AdminSubscription>({
    url: `/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    method: 'get',
  });
};

export const getGetSubscriptionKey = (
  organizationId: string,
  subscriptionId: string,
) =>
  [`/organizations/${organizationId}/subscriptions/${subscriptionId}`] as const;

export type GetSubscriptionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubscription>>
>;
export type GetSubscriptionQueryError = unknown;

export const useGetSubscription = <TError = unknown>(
  organizationId: string,
  subscriptionId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getSubscription>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(organizationId && subscriptionId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getGetSubscriptionKey(organizationId, subscriptionId) : null);
  const swrFn = () => getSubscription(organizationId, subscriptionId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Update an internal subscription in an account
 */
export const updateSubscription = (
  organizationId: string,
  subscriptionId: string,
  updateSubscriptionRequest: UpdateSubscriptionRequest,
) => {
  return customAdminApiInstance<void>({
    url: `/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateSubscriptionRequest,
  });
};

/**
 * List accounts in an organization
 */
export const listAccounts = (
  organizationId: string,
  params?: ListAccountsParams,
) => {
  return customAdminApiInstance<ListAccountsResponse>({
    url: `/organizations/${organizationId}/accounts`,
    method: 'get',
    params,
  });
};

export const getListAccountsKey = (
  organizationId: string,
  params?: ListAccountsParams,
) =>
  [
    `/organizations/${organizationId}/accounts`,
    ...(params ? [params] : []),
  ] as const;

export type ListAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAccounts>>
>;
export type ListAccountsQueryError = unknown;

export const useListAccounts = <TError = unknown>(
  organizationId: string,
  params?: ListAccountsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listAccounts>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!organizationId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListAccountsKey(organizationId, params) : null));
  const swrFn = () => listAccounts(organizationId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get details about a specific account
 */
export const getAccount = (accountId: string) => {
  return customAdminApiInstance<Account>({
    url: `/accounts/${accountId}`,
    method: 'get',
  });
};

export const getGetAccountKey = (accountId: string) =>
  [`/accounts/${accountId}`] as const;

export type GetAccountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccount>>
>;
export type GetAccountQueryError = unknown;

export const useGetAccount = <TError = unknown>(
  accountId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getAccount>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!accountId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetAccountKey(accountId) : null));
  const swrFn = () => getAccount(accountId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List teams in an account
 */
export const listTeams = (accountId: string, params?: ListTeamsParams) => {
  return customAdminApiInstance<ListTeamsResponse>({
    url: `/accounts/${accountId}/teams`,
    method: 'get',
    params,
  });
};

export const getListTeamsKey = (accountId: string, params?: ListTeamsParams) =>
  [`/accounts/${accountId}/teams`, ...(params ? [params] : [])] as const;

export type ListTeamsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listTeams>>
>;
export type ListTeamsQueryError = unknown;

export const useListTeams = <TError = unknown>(
  accountId: string,
  params?: ListTeamsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listTeams>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!accountId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListTeamsKey(accountId, params) : null));
  const swrFn = () => listTeams(accountId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get details about a specific team
 */
export const getTeam = (accountId: string, teamId: string) => {
  return customAdminApiInstance<Team>({
    url: `/accounts/${accountId}/teams/${teamId}`,
    method: 'get',
  });
};

export const getGetTeamKey = (accountId: string, teamId: string) =>
  [`/accounts/${accountId}/teams/${teamId}`] as const;

export type GetTeamQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTeam>>
>;
export type GetTeamQueryError = unknown;

export const useGetTeam = <TError = unknown>(
  accountId: string,
  teamId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getTeam>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(accountId && teamId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetTeamKey(accountId, teamId) : null));
  const swrFn = () => getTeam(accountId, teamId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List members in a team
 */
export const listTeamMembers = (
  accountId: string,
  teamId: string,
  params?: ListTeamMembersParams,
) => {
  return customAdminApiInstance<ListTeamMembersResponse>({
    url: `/accounts/${accountId}/teams/${teamId}/members`,
    method: 'get',
    params,
  });
};

export const getListTeamMembersKey = (
  accountId: string,
  teamId: string,
  params?: ListTeamMembersParams,
) =>
  [
    `/accounts/${accountId}/teams/${teamId}/members`,
    ...(params ? [params] : []),
  ] as const;

export type ListTeamMembersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listTeamMembers>>
>;
export type ListTeamMembersQueryError = unknown;

export const useListTeamMembers = <TError = unknown>(
  accountId: string,
  teamId: string,
  params?: ListTeamMembersParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listTeamMembers>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(accountId && teamId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getListTeamMembersKey(accountId, teamId, params) : null);
  const swrFn = () => listTeamMembers(accountId, teamId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get an access token to impersonate a user as a member of that account/team
 */
export const getTeamMemberImpersonationToken = (
  accountId: string,
  teamId: string,
  userId: string,
) => {
  return customAdminApiInstance<GetTeamMemberImpersonationTokenResponse>({
    url: `/accounts/${accountId}/teams/${teamId}/members/${userId}/impersonation_token`,
    method: 'get',
  });
};

export const getGetTeamMemberImpersonationTokenKey = (
  accountId: string,
  teamId: string,
  userId: string,
) =>
  [
    `/accounts/${accountId}/teams/${teamId}/members/${userId}/impersonation_token`,
  ] as const;

export type GetTeamMemberImpersonationTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTeamMemberImpersonationToken>>
>;
export type GetTeamMemberImpersonationTokenQueryError = unknown;

export const useGetTeamMemberImpersonationToken = <TError = unknown>(
  accountId: string,
  teamId: string,
  userId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getTeamMemberImpersonationToken>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(accountId && teamId && userId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getGetTeamMemberImpersonationTokenKey(accountId, teamId, userId)
        : null);
  const swrFn = () =>
    getTeamMemberImpersonationToken(accountId, teamId, userId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List integrations in an account
 */
export const listIntegrations = (
  accountId: string,
  params?: ListIntegrationsParams,
) => {
  return customAdminApiInstance<ListIntegrationsResponse>({
    url: `/accounts/${accountId}/integrations`,
    method: 'get',
    params,
  });
};

export const getListIntegrationsKey = (
  accountId: string,
  params?: ListIntegrationsParams,
) =>
  [`/accounts/${accountId}/integrations`, ...(params ? [params] : [])] as const;

export type ListIntegrationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listIntegrations>>
>;
export type ListIntegrationsQueryError = unknown;

export const useListIntegrations = <TError = unknown>(
  accountId: string,
  params?: ListIntegrationsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listIntegrations>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!accountId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListIntegrationsKey(accountId, params) : null));
  const swrFn = () => listIntegrations(accountId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get integration in an account
 */
export const getIntegration = (accountId: string, integrationId: string) => {
  return customAdminApiInstance<Integration>({
    url: `/accounts/${accountId}/integrations/${integrationId}`,
    method: 'get',
  });
};

export const getGetIntegrationKey = (
  accountId: string,
  integrationId: string,
) => [`/accounts/${accountId}/integrations/${integrationId}`] as const;

export type GetIntegrationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIntegration>>
>;
export type GetIntegrationQueryError = unknown;

export const useGetIntegration = <TError = unknown>(
  accountId: string,
  integrationId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getIntegration>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(accountId && integrationId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetIntegrationKey(accountId, integrationId) : null));
  const swrFn = () => getIntegration(accountId, integrationId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List addon instances in an account
 */
export const listAddonInstances = (
  accountId: string,
  params?: ListAddonInstancesParams,
) => {
  return customAdminApiInstance<ListAddonInstancesResponse>({
    url: `/accounts/${accountId}/addon_instances`,
    method: 'get',
    params,
  });
};

export const getListAddonInstancesKey = (
  accountId: string,
  params?: ListAddonInstancesParams,
) =>
  [
    `/accounts/${accountId}/addon_instances`,
    ...(params ? [params] : []),
  ] as const;

export type ListAddonInstancesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAddonInstances>>
>;
export type ListAddonInstancesQueryError = unknown;

export const useListAddonInstances = <TError = unknown>(
  accountId: string,
  params?: ListAddonInstancesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listAddonInstances>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!accountId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListAddonInstancesKey(accountId, params) : null));
  const swrFn = () => listAddonInstances(accountId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get a specific addon instance in an account
 */
export const getAddonInstance = (
  accountId: string,
  addonInstanceId: string,
) => {
  return customAdminApiInstance<AddonInstance>({
    url: `/accounts/${accountId}/addon_instances/${addonInstanceId}`,
    method: 'get',
  });
};

export const getGetAddonInstanceKey = (
  accountId: string,
  addonInstanceId: string,
) => [`/accounts/${accountId}/addon_instances/${addonInstanceId}`] as const;

export type GetAddonInstanceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAddonInstance>>
>;
export type GetAddonInstanceQueryError = unknown;

export const useGetAddonInstance = <TError = unknown>(
  accountId: string,
  addonInstanceId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getAddonInstance>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(accountId && addonInstanceId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getGetAddonInstanceKey(accountId, addonInstanceId) : null);
  const swrFn = () => getAddonInstance(accountId, addonInstanceId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List clusters in an account
 */
export const listClusters = (
  accountId: string,
  params?: ListClustersParams,
) => {
  return customAdminApiInstance<ListClustersResponse>({
    url: `/accounts/${accountId}/clusters`,
    method: 'get',
    params,
  });
};

export const getListClustersKey = (
  accountId: string,
  params?: ListClustersParams,
) => [`/accounts/${accountId}/clusters`, ...(params ? [params] : [])] as const;

export type ListClustersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listClusters>>
>;
export type ListClustersQueryError = unknown;

export const useListClusters = <TError = unknown>(
  accountId: string,
  params?: ListClustersParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listClusters>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!accountId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListClustersKey(accountId, params) : null));
  const swrFn = () => listClusters(accountId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Delete a specific cluster in an account
 */
export const deleteCluster = (accountId: string, clusterId: string) => {
  return customAdminApiInstance<void>({
    url: `/accounts/${accountId}/clusters/${clusterId}`,
    method: 'delete',
  });
};

/**
 * Get a specific cluster in an account
 */
export const getCluster = (accountId: string, clusterId: string) => {
  return customAdminApiInstance<Cluster>({
    url: `/accounts/${accountId}/clusters/${clusterId}`,
    method: 'get',
  });
};

export const getGetClusterKey = (accountId: string, clusterId: string) =>
  [`/accounts/${accountId}/clusters/${clusterId}`] as const;

export type GetClusterQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCluster>>
>;
export type GetClusterQueryError = unknown;

export const useGetCluster = <TError = unknown>(
  accountId: string,
  clusterId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getCluster>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(accountId && clusterId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetClusterKey(accountId, clusterId) : null));
  const swrFn = () => getCluster(accountId, clusterId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Update a specific cluster in an account
 */
export const updateCluster = (
  accountId: string,
  clusterId: string,
  updateClusterRequest: UpdateClusterRequest,
) => {
  return customAdminApiInstance<void>({
    url: `/accounts/${accountId}/clusters/${clusterId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateClusterRequest,
  });
};

/**
 * List LAR Status' in a cluster
 */
export const listLARStatus = (
  accountId: string,
  clusterId: string,
  params?: ListLARStatusParams,
) => {
  return customAdminApiInstance<ListLARStatusResponse>({
    url: `/accounts/${accountId}/clusters/${clusterId}/lars/status`,
    method: 'get',
    params,
  });
};

export const getListLARStatusKey = (
  accountId: string,
  clusterId: string,
  params?: ListLARStatusParams,
) =>
  [
    `/accounts/${accountId}/clusters/${clusterId}/lars/status`,
    ...(params ? [params] : []),
  ] as const;

export type ListLARStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof listLARStatus>>
>;
export type ListLARStatusQueryError = unknown;

export const useListLARStatus = <TError = unknown>(
  accountId: string,
  clusterId: string,
  params?: ListLARStatusParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listLARStatus>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(accountId && clusterId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getListLARStatusKey(accountId, clusterId, params) : null);
  const swrFn = () => listLARStatus(accountId, clusterId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get LAR Status in a cluster
 */
export const getLARStatus = (
  accountId: string,
  clusterId: string,
  larId: string,
) => {
  return customAdminApiInstance<LARStatus>({
    url: `/accounts/${accountId}/clusters/${clusterId}/lars/status/${larId}`,
    method: 'get',
  });
};

export const getGetLARStatusKey = (
  accountId: string,
  clusterId: string,
  larId: string,
) =>
  [
    `/accounts/${accountId}/clusters/${clusterId}/lars/status/${larId}`,
  ] as const;

export type GetLARStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLARStatus>>
>;
export type GetLARStatusQueryError = unknown;

export const useGetLARStatus = <TError = unknown>(
  accountId: string,
  clusterId: string,
  larId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getLARStatus>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(accountId && clusterId && larId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getGetLARStatusKey(accountId, clusterId, larId) : null);
  const swrFn = () => getLARStatus(accountId, clusterId, larId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Update the status of a LAR
 */
export const updateLARStatus = (
  accountId: string,
  clusterId: string,
  larId: string,
  updateLARStatusRequest: UpdateLARStatusRequest,
) => {
  return customAdminApiInstance<void>({
    url: `/accounts/${accountId}/clusters/${clusterId}/lars/status/${larId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateLARStatusRequest,
  });
};

/**
 * List cloud connections in an account
 */
export const listCloudConnections = (
  accountId: string,
  params?: ListCloudConnectionsParams,
) => {
  return customAdminApiInstance<ListCloudConnectionsResponse>({
    url: `/accounts/${accountId}/connection/clouds`,
    method: 'get',
    params,
  });
};

export const getListCloudConnectionsKey = (
  accountId: string,
  params?: ListCloudConnectionsParams,
) =>
  [
    `/accounts/${accountId}/connection/clouds`,
    ...(params ? [params] : []),
  ] as const;

export type ListCloudConnectionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCloudConnections>>
>;
export type ListCloudConnectionsQueryError = unknown;

export const useListCloudConnections = <TError = unknown>(
  accountId: string,
  params?: ListCloudConnectionsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listCloudConnections>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!accountId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListCloudConnectionsKey(accountId, params) : null));
  const swrFn = () => listCloudConnections(accountId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get a specific cloud connection in an account
 */
export const getCloudConnection = (accountId: string, cloudId: string) => {
  return customAdminApiInstance<CloudConnection>({
    url: `/accounts/${accountId}/connection/clouds/${cloudId}`,
    method: 'get',
  });
};

export const getGetCloudConnectionKey = (accountId: string, cloudId: string) =>
  [`/accounts/${accountId}/connection/clouds/${cloudId}`] as const;

export type GetCloudConnectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCloudConnection>>
>;
export type GetCloudConnectionQueryError = unknown;

export const useGetCloudConnection = <TError = unknown>(
  accountId: string,
  cloudId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getCloudConnection>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(accountId && cloudId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetCloudConnectionKey(accountId, cloudId) : null));
  const swrFn = () => getCloudConnection(accountId, cloudId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Create a new action for a cloud connection
 */
export const createCloudConnectionAction = (
  accountId: string,
  cloudId: string,
  createCloudConnectionActionRequest: CreateCloudConnectionActionRequest,
) => {
  return customAdminApiInstance<CloudConnectionAction>({
    url: `/accounts/${accountId}/connection/clouds/${cloudId}/actions`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createCloudConnectionActionRequest,
  });
};

/**
 * List upgrades in an account
 */
export const listUpgrades = (
  accountId: string,
  params?: ListUpgradesParams,
) => {
  return customAdminApiInstance<ListUpgradePlansResponse>({
    url: `/accounts/${accountId}/upgrades`,
    method: 'get',
    params,
  });
};

export const getListUpgradesKey = (
  accountId: string,
  params?: ListUpgradesParams,
) => [`/accounts/${accountId}/upgrades`, ...(params ? [params] : [])] as const;

export type ListUpgradesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgrades>>
>;
export type ListUpgradesQueryError = unknown;

export const useListUpgrades = <TError = unknown>(
  accountId: string,
  params?: ListUpgradesParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listUpgrades>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!accountId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListUpgradesKey(accountId, params) : null));
  const swrFn = () => listUpgrades(accountId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get upgrade in an account
 */
export const getUpgrade = (accountId: string, upgradeId: string) => {
  return customAdminApiInstance<UpgradePlanAdminView>({
    url: `/accounts/${accountId}/upgrades/${upgradeId}`,
    method: 'get',
  });
};

export const getGetUpgradeKey = (accountId: string, upgradeId: string) =>
  [`/accounts/${accountId}/upgrades/${upgradeId}`] as const;

export type GetUpgradeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpgrade>>
>;
export type GetUpgradeQueryError = unknown;

export const useGetUpgrade = <TError = unknown>(
  accountId: string,
  upgradeId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getUpgrade>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(accountId && upgradeId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetUpgradeKey(accountId, upgradeId) : null));
  const swrFn = () => getUpgrade(accountId, upgradeId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Update an upgrade in an account
 */
export const updateUpgrade = (
  accountId: string,
  upgradeId: string,
  updateUpgradePlanRequest: UpdateUpgradePlanRequest,
) => {
  return customAdminApiInstance<UpgradePlanAdminView>({
    url: `/accounts/${accountId}/upgrades/${upgradeId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: updateUpgradePlanRequest,
  });
};

/**
 * list the stages for this upgrade
 */
export const listUpgradeStages = (accountId: string, upgradeId: string) => {
  return customAdminApiInstance<ListUpgradePlanStagesResponse>({
    url: `/accounts/${accountId}/upgrades/${upgradeId}/stages`,
    method: 'get',
  });
};

export const getListUpgradeStagesKey = (accountId: string, upgradeId: string) =>
  [`/accounts/${accountId}/upgrades/${upgradeId}/stages`] as const;

export type ListUpgradeStagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradeStages>>
>;
export type ListUpgradeStagesQueryError = unknown;

export const useListUpgradeStages = <TError = unknown>(
  accountId: string,
  upgradeId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradeStages>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(accountId && upgradeId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListUpgradeStagesKey(accountId, upgradeId) : null));
  const swrFn = () => listUpgradeStages(accountId, upgradeId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * create stages for this upgrade - will fail if stages already exist
 */
export const createUpgradeStages = (
  accountId: string,
  upgradeId: string,
  createUpgradePlanStagesRequest: CreateUpgradePlanStagesRequest,
) => {
  return customAdminApiInstance<void>({
    url: `/accounts/${accountId}/upgrades/${upgradeId}/stages`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createUpgradePlanStagesRequest,
  });
};

/**
 * update stages for this upgrade - will fail if stages already exist
 */
export const updateUpgradeStages = (
  accountId: string,
  upgradeId: string,
  updateUpgradePlanStagesRequest: UpdateUpgradePlanStagesRequest,
) => {
  return customAdminApiInstance<void>({
    url: `/accounts/${accountId}/upgrades/${upgradeId}/stages`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateUpgradePlanStagesRequest,
  });
};

/**
 * List upgrades across all organizations and accounts
 */
export const globalListUpgrades = (params?: GlobalListUpgradesParams) => {
  return customAdminApiInstance<ListUpgradePlansResponse>({
    url: `/upgrades`,
    method: 'get',
    params,
  });
};

export const getGlobalListUpgradesKey = (params?: GlobalListUpgradesParams) =>
  [`/upgrades`, ...(params ? [params] : [])] as const;

export type GlobalListUpgradesQueryResult = NonNullable<
  Awaited<ReturnType<typeof globalListUpgrades>>
>;
export type GlobalListUpgradesQueryError = unknown;

export const useGlobalListUpgrades = <TError = unknown>(
  params?: GlobalListUpgradesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof globalListUpgrades>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGlobalListUpgradesKey(params) : null));
  const swrFn = () => globalListUpgrades(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List upgrade templates in an account
 */
export const listUpgradeTemplates = (
  accountId: string,
  params?: ListUpgradeTemplatesParams,
) => {
  return customAdminApiInstance<ListUpgradeTemplatesResponse>({
    url: `/accounts/${accountId}/upgrade_templates`,
    method: 'get',
    params,
  });
};

export const getListUpgradeTemplatesKey = (
  accountId: string,
  params?: ListUpgradeTemplatesParams,
) =>
  [
    `/accounts/${accountId}/upgrade_templates`,
    ...(params ? [params] : []),
  ] as const;

export type ListUpgradeTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradeTemplates>>
>;
export type ListUpgradeTemplatesQueryError = unknown;

export const useListUpgradeTemplates = <TError = unknown>(
  accountId: string,
  params?: ListUpgradeTemplatesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradeTemplates>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!accountId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListUpgradeTemplatesKey(accountId, params) : null));
  const swrFn = () => listUpgradeTemplates(accountId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get upgrade template in an account
 */
export const getUpgradeTemplate = (
  accountId: string,
  upgradeTemplateId: string,
) => {
  return customAdminApiInstance<UpgradeTemplateAdminView>({
    url: `/accounts/${accountId}/upgrade_templates/${upgradeTemplateId}`,
    method: 'get',
  });
};

export const getGetUpgradeTemplateKey = (
  accountId: string,
  upgradeTemplateId: string,
) => [`/accounts/${accountId}/upgrade_templates/${upgradeTemplateId}`] as const;

export type GetUpgradeTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpgradeTemplate>>
>;
export type GetUpgradeTemplateQueryError = unknown;

export const useGetUpgradeTemplate = <TError = unknown>(
  accountId: string,
  upgradeTemplateId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getUpgradeTemplate>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(accountId && upgradeTemplateId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getGetUpgradeTemplateKey(accountId, upgradeTemplateId)
        : null);
  const swrFn = () => getUpgradeTemplate(accountId, upgradeTemplateId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Update an upgrade in an account
 */
export const updateUpgradeTemplate = (
  accountId: string,
  upgradeTemplateId: string,
  updateUpgradeTemplateRequest: UpdateUpgradeTemplateRequest,
) => {
  return customAdminApiInstance<UpgradeTemplateAdminView>({
    url: `/accounts/${accountId}/upgrade_templates/${upgradeTemplateId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: updateUpgradeTemplateRequest,
  });
};

/**
 * list the stages for this upgrade template
 */
export const listUpgradeTemplateStages = (
  accountId: string,
  upgradeTemplateId: string,
) => {
  return customAdminApiInstance<ListUpgradeTemplateStagesResponse>({
    url: `/accounts/${accountId}/upgrade_templates/${upgradeTemplateId}/stages`,
    method: 'get',
  });
};

export const getListUpgradeTemplateStagesKey = (
  accountId: string,
  upgradeTemplateId: string,
) =>
  [
    `/accounts/${accountId}/upgrade_templates/${upgradeTemplateId}/stages`,
  ] as const;

export type ListUpgradeTemplateStagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradeTemplateStages>>
>;
export type ListUpgradeTemplateStagesQueryError = unknown;

export const useListUpgradeTemplateStages = <TError = unknown>(
  accountId: string,
  upgradeTemplateId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradeTemplateStages>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(accountId && upgradeTemplateId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListUpgradeTemplateStagesKey(accountId, upgradeTemplateId)
        : null);
  const swrFn = () => listUpgradeTemplateStages(accountId, upgradeTemplateId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * create stages for this upgrade template - will fail if stages already exist
 */
export const createUpgradeTemplateStages = (
  accountId: string,
  upgradeTemplateId: string,
  createUpgradeTemplateStagesRequest: CreateUpgradeTemplateStagesRequest,
) => {
  return customAdminApiInstance<void>({
    url: `/accounts/${accountId}/upgrade_templates/${upgradeTemplateId}/stages`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createUpgradeTemplateStagesRequest,
  });
};

/**
 * update stages for this upgrade template
 */
export const updateUpgradeTemplateStages = (
  accountId: string,
  upgradeTemplateId: string,
  updateUpgradeTemplateStagesRequest: UpdateUpgradeTemplateStagesRequest,
) => {
  return customAdminApiInstance<void>({
    url: `/accounts/${accountId}/upgrade_templates/${upgradeTemplateId}/stages`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateUpgradeTemplateStagesRequest,
  });
};

/**
 * List upgrade templates across all organizations and accounts
 */
export const globalListUpgradeTemplates = (
  params?: GlobalListUpgradeTemplatesParams,
) => {
  return customAdminApiInstance<ListUpgradeTemplatesResponse>({
    url: `/upgrade_templates`,
    method: 'get',
    params,
  });
};

export const getGlobalListUpgradeTemplatesKey = (
  params?: GlobalListUpgradeTemplatesParams,
) => [`/upgrade_templates`, ...(params ? [params] : [])] as const;

export type GlobalListUpgradeTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof globalListUpgradeTemplates>>
>;
export type GlobalListUpgradeTemplatesQueryError = unknown;

export const useGlobalListUpgradeTemplates = <TError = unknown>(
  params?: GlobalListUpgradeTemplatesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof globalListUpgradeTemplates>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGlobalListUpgradeTemplatesKey(params) : null));
  const swrFn = () => globalListUpgradeTemplates(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List addons defined in AddonHub
 */
export const listAddons = (params?: ListAddonsParams) => {
  return customAdminApiInstance<ListAddonResponse>({
    url: `/addons`,
    method: 'get',
    params,
  });
};

export const getListAddonsKey = (params?: ListAddonsParams) =>
  [`/addons`, ...(params ? [params] : [])] as const;

export type ListAddonsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAddons>>
>;
export type ListAddonsQueryError = unknown;

export const useListAddons = <TError = unknown>(
  params?: ListAddonsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listAddons>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getListAddonsKey(params) : null));
  const swrFn = () => listAddons(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get details for a specific addon from AddonHub
 */
export const getAddon = (addonId: string) => {
  return customAdminApiInstance<Addon>({
    url: `/addons/${addonId}`,
    method: 'get',
  });
};

export const getGetAddonKey = (addonId: string) =>
  [`/addons/${addonId}`] as const;

export type GetAddonQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAddon>>
>;
export type GetAddonQueryError = unknown;

export const useGetAddon = <TError = unknown>(
  addonId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getAddon>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!addonId;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetAddonKey(addonId) : null));
  const swrFn = () => getAddon(addonId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get details for a specific addon image from AddonHub
 */
export const getAddonImage = (imageHash: string) => {
  return customAdminApiInstance<AddonImageDetails>({
    url: `/addon_images/${imageHash}`,
    method: 'get',
  });
};

export const getGetAddonImageKey = (imageHash: string) =>
  [`/addon_images/${imageHash}`] as const;

export type GetAddonImageQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAddonImage>>
>;
export type GetAddonImageQueryError = unknown;

export const useGetAddonImage = <TError = unknown>(
  imageHash: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getAddonImage>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!imageHash;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetAddonImageKey(imageHash) : null));
  const swrFn = () => getAddonImage(imageHash);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List users based on filters provided in the query parameters
 */
export const listUsers = (params?: ListUsersParams) => {
  return customAdminApiInstance<ListUserResponse>({
    url: `/users`,
    method: 'get',
    params,
  });
};

export const getListUsersKey = (params?: ListUsersParams) =>
  [`/users`, ...(params ? [params] : [])] as const;

export type ListUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUsers>>
>;
export type ListUsersQueryError = unknown;

export const useListUsers = <TError = unknown>(
  params?: ListUsersParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listUsers>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getListUsersKey(params) : null));
  const swrFn = () => listUsers(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Endpoint to get user's state in DB
 */
export const getUser = (userId: string) => {
  return customAdminApiInstance<User>({
    url: `/users/${userId}`,
    method: 'get',
  });
};

export const getGetUserKey = (userId: string) => [`/users/${userId}`] as const;

export type GetUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUser>>
>;
export type GetUserQueryError = unknown;

export const useGetUser = <TError = unknown>(
  userId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getUser>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!userId;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetUserKey(userId) : null));
  const swrFn = () => getUser(userId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Endpoint to update user's engagement state in DB
 */
export const updateUser = (userId: string, userEngagement: UserEngagement) => {
  return customAdminApiInstance<User>({
    url: `/users/${userId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: userEngagement,
  });
};
