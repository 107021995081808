import {
  ColumnLayout,
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
  Tabs,
  Alert,
} from '@cloudscape-design/components';
import { useParams } from 'react-router';
import { useSplitPanel } from 'contexts/split-panel';
import { AccountTeams } from './teams';
import { AccountIntegrations } from './integrations';
import { AccountClusters } from './clusters';
import { AccountUpgrades } from './upgrades';
import CopyText from 'components/copy-text';
import { ValueWithLabel } from 'components/value-with-label';
import { useGetAccount } from 'api/admin';
import { format } from 'date-fns';
import { useState } from 'react';
import { AccountAddonInstances } from './addons';
import { AccountCloudConnections } from './cloud_connections';

export function AccountOverview(): JSX.Element {
  const { orgId, accountId } = useParams();
  const [errors, setError] = useState<string[]>([]);
  const updateSplitPanel = useSplitPanel();
  const { data } = useGetAccount(accountId || '');

  return (
    <ContentLayout header={<Header variant="h1">Account {accountId}</Header>}>
      <SpaceBetween direction="vertical" size="m">
        <Container header={<Header variant="h3">Account Details</Header>}>
          <ColumnLayout columns={3} variant="text-grid">
            <ValueWithLabel label="ID">
              <CopyText
                copyText={accountId || ''}
                copyButtonLabel="Copy Account ID"
                successText="Account ID copied"
                errorText="Account ID failed to copy"
              />
            </ValueWithLabel>
            <ValueWithLabel label="Slug">
              <CopyText
                copyText={data?.slug || ''}
                copyButtonLabel="Copy Account Slug"
                successText="Account Slug copied"
                errorText="Account Slug failed to copy"
              />
            </ValueWithLabel>
            <ValueWithLabel label="Created">
              {format(data?.created || 0, 'Pp')}
            </ValueWithLabel>
          </ColumnLayout>
        </Container>
        {errors.map((error, idx) => (
          <Alert
            dismissAriaLabel="Close alert"
            dismissible={true}
            type="error"
            key={idx}
            onDismiss={() => setError([])}
          >
            {error}
          </Alert>
        ))}
        <Tabs
          variant="container"
          tabs={[
            {
              label: 'Clusters',
              id: 'clusters',
              content: (
                <AccountClusters
                  orgId={orgId || ''}
                  accountId={accountId || ''}
                />
              ),
            },
            {
              label: 'Addons',
              id: 'addons',
              content: (
                <AccountAddonInstances
                  orgId={orgId || ''}
                  accountId={accountId || ''}
                />
              ),
            },
            {
              label: 'Upgrades',
              id: 'upgrades',
              content: (
                <AccountUpgrades
                  orgId={orgId || ''}
                  accountId={accountId || ''}
                />
              ),
            },
            {
              label: 'Integrations',
              id: 'integrations',
              content: (
                <AccountIntegrations
                  orgId={orgId || ''}
                  accountId={accountId || ''}
                />
              ),
            },
            {
              label: 'Cloud Connections',
              id: 'cloudConnections',
              content: (
                <AccountCloudConnections
                  orgId={orgId || ''}
                  accountId={accountId || ''}
                />
              ),
            },
            {
              label: 'Teams',
              id: 'teams',
              content: (
                <AccountTeams orgId={orgId || ''} accountId={accountId || ''} />
              ),
            },
          ]}
          onChange={() => updateSplitPanel(undefined)}
        />
      </SpaceBetween>
    </ContentLayout>
  );
}
