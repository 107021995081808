import { OrganizationDetails } from './details';
import { ContentLayout, Header } from '@cloudscape-design/components';
import { useListOrganizationsInfinite } from 'api/admin-infinite';
import { Organization } from 'api/admin-models';
import { FilterableTable } from 'components/tables/filterable-table';
import { useSplitPanel } from 'contexts/split-panel';
import { formatISO } from 'date-fns';

export function OrganizationsIndex(): JSX.Element {
  const updateSplitPanel = useSplitPanel();
  const { data } = useListOrganizationsInfinite();
  const tableData =
    data === undefined
      ? undefined
      : data.reduce<Organization[]>((acc, val) => acc.concat(...val.data), []);

  return (
    <ContentLayout header={<Header variant="h1">Organizations</Header>}>
      <FilterableTable
        variant={'container'}
        selectionMode="single"
        data={tableData}
        columns={[
          {
            id: 'id',
            label: 'ID',
            cell: (item) => item.id || '-',

            defaultVisible: true,
            alwaysVisible: true,

            sortingField: 'id',
            filterOperators: ['='],
          },
          {
            id: 'slug',
            label: 'Slug',
            cell: (item) => item.slug || '-',

            defaultVisible: true,

            sortingField: 'slug',
            filterOperators: ['=', '!=', ':', '!:'],
          },
          {
            id: 'name',
            label: 'Name',
            cell: (item) => item.name || '-',

            defaultVisible: true,

            sortingField: 'name',
            filterOperators: ['=', '!=', ':', '!:'],
          },
          {
            id: 'created',
            label: 'Created',
            cell: (item) => formatISO(new Date(item.created * 1000)),

            defaultVisible: true,

            sortingField: 'created',
            filterOperators: ['=', '!=', ':', '!:'],
          },
          {
            id: 'updated',
            label: 'Last Updated',
            cell: (item) => formatISO(new Date((item.updated || 0) * 1000)),

            defaultVisible: true,

            sortingField: 'updated',
            filterOperators: ['=', '!=', ':', '!:'],
          },
        ]}
        selectionTrackBy={(item) => item.id}
        onSelectionChange={({ detail }) => {
          updateSplitPanel(
            <OrganizationDetails orgId={detail.selectedItems[0].id} />,
          );
        }}
      />
    </ContentLayout>
  );
}
