import { Button, ButtonProps } from '@cloudscape-design/components';
import { useState } from 'react';

export interface ProcessingButtonProps extends Omit<ButtonProps, 'onClick'> {
  readonly onClick: () => Promise<void>;
}

export default function ProcessingButton(props: ProcessingButtonProps) {
  const [processing, setProcessing] = useState(false);

  return (
    <Button
      {...props}
      loading={processing}
      onClick={async () => {
        setProcessing(true);
        try {
          await props.onClick();
        } finally {
          setProcessing(false);
        }
      }}
    >
      {props.children}
    </Button>
  );
}
