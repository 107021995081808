import {
  Alert,
  Box,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  Spinner,
  SplitPanel,
  Table,
} from '@cloudscape-design/components';
import { formatISO } from 'date-fns';
import { splitPanelI18nStrings } from 'src/i18n/split-panel';

import { useGetOrganization, useListAccounts } from 'api/admin';
import { OrganizationSubscriptions } from './subscriptions';
import { useState } from 'react';

const ValueWithLabel = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

export function OrganizationDetails({ orgId }: { orgId: string }) {
  const { data: orgDetails, isLoading: isLoadingOrgDetails } =
    useGetOrganization(orgId || '');
  const [errors, setError] = useState<string[]>([]);
  const { data: accounts, isLoading: isLoadingAccounts } = useListAccounts(
    orgId || '',
  );

  if (isLoadingOrgDetails || orgDetails === undefined) {
    return (
      <SplitPanel header={`Org: ${orgId}`} i18nStrings={splitPanelI18nStrings}>
        <Spinner /> Still Loading
      </SplitPanel>
    );
  }
  return (
    <SplitPanel
      header={`Org: ${orgDetails.name}`}
      i18nStrings={splitPanelI18nStrings}
    >
      <SpaceBetween size="l">
        <Container header={<Header variant="h2">Org details </Header>}>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label="Name">{orgDetails.name}</ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="ID">{orgDetails.id}</ValueWithLabel>
              <ValueWithLabel label="Slug">{orgDetails.slug}</ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Created">
                {formatISO(new Date(orgDetails.created * 1000))}
              </ValueWithLabel>
              <ValueWithLabel label="Last Updated">
                {formatISO(new Date((orgDetails.updated || 0) * 1000))}
              </ValueWithLabel>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
        {errors.map((error, idx) => (
          <Alert
            dismissAriaLabel="Close alert"
            dismissible={true}
            type="error"
            key={idx}
            onDismiss={() => setError([])}
          >
            {error}
          </Alert>
        ))}

        <Table
          resizableColumns
          loading={isLoadingAccounts}
          loadingText="Loading resources"
          items={accounts?.data || []}
          columnDefinitions={[
            {
              id: 'id',
              header: 'ID',
              cell: (item) => (
                <Link href={`/organizations/${orgId}/accounts/${item.id}/`}>
                  {item.id || '-'}
                </Link>
              ),
              sortingField: 'id',
            },
            {
              id: 'created',
              header: 'Created',
              cell: (item) => formatISO(new Date(item.created * 1000)),
              sortingField: 'created',
            },
            {
              id: 'updated',
              header: 'Last Updated',
              cell: (item) => formatISO(new Date((item.updated || 0) * 1000)),
              sortingField: 'updated',
            },
          ]}
          header={<Header variant="h2">Accounts</Header>}
          empty={
            <Box textAlign="center" color="inherit">
              {' '}
              <b>No resources</b>{' '}
              <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                {' '}
                No resources to display.{' '}
              </Box>{' '}
            </Box>
          }
        />
        <OrganizationSubscriptions
          orgId={orgId || ''}
          setError={(error) => setError([error])}
        />
      </SpaceBetween>
    </SplitPanel>
  );
}
