import {
  Button,
  Header,
  SpaceBetween,
  SplitPanel,
} from '@cloudscape-design/components';
import { FilterableTable } from 'components/tables/filterable-table';
import { formatISO } from 'date-fns';
import {
  getTeamMemberImpersonationToken,
  useGetTeam,
  useListTeamMembers,
  useListTeams,
} from 'api/admin';
import { useSplitPanel } from 'contexts/split-panel';
import { splitPanelI18nStrings } from 'i18n/split-panel';
import { useState } from 'react';

export function AccountTeams(props: {
  orgId: string;
  accountId: string;
}): JSX.Element {
  const updateSplitPanel = useSplitPanel();
  const { data: teamsData } = useListTeams(props.accountId);
  return (
    <FilterableTable
      selectionMode="single"
      data={teamsData?.data}
      columns={[
        {
          id: 'id',
          label: 'ID',
          cell: (item) => item.id || '-',

          defaultVisible: false,

          sortingField: 'id',
          filterOperators: ['='],
        },
        {
          id: 'slug',
          label: 'Slug',
          cell: (item) => item.slug || '-',

          defaultVisible: true,

          sortingField: 'slug',
          filterOperators: ['=', '!=', ':', '!:'],
        },
        {
          id: 'name',
          label: 'Name',
          cell: (item) => item.name || '-',

          defaultVisible: true,

          sortingField: 'name',
          filterOperators: ['=', '!=', ':', '!:'],
        },
        {
          id: 'created',
          label: 'Created',
          cell: (item) => formatISO(new Date(item.created * 1000)),

          defaultVisible: true,

          sortingField: 'created',
          filterOperators: ['=', '!=', ':', '!:'],
        },
        {
          id: 'updated',
          label: 'Last Updated',
          cell: (item) => formatISO(new Date((item.updated || 0) * 1000)),

          defaultVisible: true,

          sortingField: 'updated',
          filterOperators: ['=', '!=', ':', '!:'],
        },
      ]}
      selectionTrackBy={(item) => item.id}
      onSelectionChange={(event) =>
        updateSplitPanel(
          <AccountTeamsDetails
            orgId={props.orgId}
            accountId={props.accountId}
            teamId={event.detail.selectedItems[0].id}
          />,
        )
      }
    />
  );
}

function AccountTeamsDetails(props: {
  orgId: string;
  accountId: string;
  teamId: string;
}): JSX.Element {
  const { data: teamData } = useGetTeam(props.accountId, props.teamId);
  const { data: membersData } = useListTeamMembers(
    props.accountId,
    props.teamId,
  );
  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    undefined,
  );
  const [processing, setProcessing] = useState<boolean>(false);

  const onImpersonateClick = async () => {
    if (selectedItem === undefined) {
      return;
    }
    try {
      setProcessing(true);
      const token = await getTeamMemberImpersonationToken(
        props.accountId,
        props.teamId,
        selectedItem,
      );
      window.open(
        `${import.meta.env.VITE_APP_ENDPOINT}/login?federation_token=${
          token.access_token.token
        }`,
        '_blank',
      );
    } finally {
      setProcessing(false);
    }
  };

  return (
    <SplitPanel
      header={`Team: ${teamData?.name || props.teamId}`}
      i18nStrings={splitPanelI18nStrings}
    >
      <FilterableTable
        selectionMode="single"
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <Button
                  loading={processing}
                  disabled={selectedItem === undefined}
                  onClick={() => void onImpersonateClick()}
                >
                  Impersonate
                </Button>
              </SpaceBetween>
            }
          ></Header>
        }
        data={membersData?.data}
        columns={[
          {
            id: 'user_id',
            label: 'ID',
            cell: (item) => item.user_id,

            defaultVisible: true,

            sortingField: 'user_id',
          },
          {
            id: 'user_name',
            label: 'Name',
            cell: (item) => item.user_name,

            defaultVisible: true,
            alwaysVisible: true,

            sortingField: 'user_name',
            filterOperators: ['=', '!=', ':', '!:'],
          },
          {
            id: 'user_email',
            label: 'Email',
            cell: (item) => item.user_email,

            defaultVisible: true,

            sortingField: 'user_email',
            filterOperators: ['=', '!=', ':', '!:'],
          },
          {
            id: 'date_added',
            label: 'Added',
            cell: (item) => formatISO(new Date(item.date_added * 1000)),

            defaultVisible: true,

            sortingField: 'date_added',
            filterOperators: ['=', '!=', ':', '!:'],
          },
        ]}
        selectionTrackBy={(item) => item.user_id}
        onSelectionChange={(event) =>
          setSelectedItem(event.detail.selectedItems[0].user_id)
        }
      />
    </SplitPanel>
  );
}
