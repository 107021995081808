import { ContentLayout, Header } from '@cloudscape-design/components';
import { useState } from 'react';

import { useListAddons } from 'api/admin';
import { Addon } from 'api/admin-models';

import { useSplitPanel } from 'contexts/split-panel';

import { AddonDetails } from './details';
import { FilterableTable } from 'components/tables/filterable-table';
import { PropertyFilterToken } from '@cloudscape-design/collection-hooks';

export function AddonsIndex(): JSX.Element {
  const updateSplitPanel = useSplitPanel();
  const { data } = useListAddons({ page_size: 1000 });

  const [selectedItems, setSelectedItems] = useState<Addon[]>([]);

  return (
    <ContentLayout header={<Header variant="h1">Addons</Header>}>
      <FilterableTable<Addon>
        selectionMode={'single'}
        variant={'container'}
        data={data?.data || undefined}
        columns={[
          {
            id: 'id',
            label: 'ID',
            cell: (item) => item.id || '-',
            sortingField: 'id',
            defaultVisible: false,
            filterOperators: ['=', '!='],
          },
          {
            id: 'name',
            label: 'Name',
            cell: (item) => item.name || '-',
            sortingField: 'name',

            defaultVisible: true,

            filterOperators: ['=', '!=', ':', '!:'],
          },
          {
            id: 'website',
            label: 'Website',
            cell: (item) => item.website || '-',
            sortingField: 'website',
            defaultVisible: true,
            filterOperators: ['=', '!=', ':', '!:'],
          },
          {
            id: 'cloudProviders',
            label: 'Cloud Providers',
            cell: (item) =>
              (item.managed_addon_providers || [])
                .map((p) => p.name)
                .join(', ') || '-',
            sortingField: 'cloudProviders',
            defaultVisible: true,
            filterOperators: ['=', '!=', ':', '!:'],
          },
        ]}
        defaultFilterQuery={async (searchParams) => {
          const tokens: PropertyFilterToken[] = [];

          if (searchParams.get('select')) {
            tokens.push({
              propertyKey: 'id',
              value: searchParams.get('select'),
              operator: '=',
            });
          }
          return Promise.resolve({ tokens: tokens, operation: 'and' });
        }}
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail.selectedItems);
          updateSplitPanel(
            <AddonDetails
              addonId={detail.selectedItems[0].id}
              addonName={detail.selectedItems[0].name}
            />,
          );
        }}
      />
    </ContentLayout>
  );
}
