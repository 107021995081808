import {
  ListUpgradesResponse,
  UpgradeCreationStatus,
  UpgradePlanRequesterUser,
  UpgradeStatus,
} from 'api/admin-models';

export interface UpgradesTableElement {
  AccountId: string;
  Id: string;
  Name: string;
  Status: UpgradeStatus;
  CreationStatus: UpgradeCreationStatus;
  RequestedBy?: UpgradePlanRequesterUser;
  Assignee?: string;

  Created: number;
  Updated?: number;
}

export function fromApiResponses(
  data: ListUpgradesResponse[] | undefined,
): UpgradesTableElement[] | undefined {
  if (data === undefined) {
    return undefined;
  }

  const results = data.reduce<UpgradesTableElement[]>((acc, res) => {
    const mappedData = res.data.map<UpgradesTableElement>((uav) => ({
      AccountId: uav.account_id,
      Id: uav.upgrade.id,
      Name: uav.upgrade.name,
      Status: uav.upgrade.status,
      CreationStatus: uav.creation_status,
      Assignee: uav.assignee,
      Created: uav.upgrade.created,
      Updated: uav.upgrade.updated,
      RequestedBy: uav.upgrade.requested_by,
    }));

    return acc.concat(...mappedData);
  }, []);

  return results;
}
