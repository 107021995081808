import {
  AppLayout,
  AppLayoutProps,
  Flashbar,
  TopNavigation,
} from '@cloudscape-design/components';
import {
  Density,
  Mode,
  applyDensity,
  applyMode,
} from '@cloudscape-design/global-styles';
import '@cloudscape-design/global-styles/index.css';
import { useEffect, useRef, useState } from 'react';
import { Outlet, RouterProvider, useLocation } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import {
  STSClient,
  GetCallerIdentityCommand,
  GetCallerIdentityCommandOutput,
} from '@aws-sdk/client-sts';
import { Auth } from 'aws-amplify';

import { Breadcrumbs } from 'components/breadcrumbs';
import { Navigation } from 'components/navigation';

import { HelpPanelProvider } from 'contexts/help-panel';
import { SplitPanelProvider } from 'contexts/split-panel';

import { useSplitPanel } from 'hooks/split-panel';

import { AddonsIndex } from 'pages/addons';
import { BasePage } from 'pages/dashboard';
import { OrganizationsIndex } from 'pages/organizations';
import { UpgradesIndex } from 'pages/upgrade_plans';

import { ProtectedRoute } from './components/protectedRoute';
import { appLayoutAriaLabels } from './i18n/app-layout';
import { AccountOverview } from 'pages/organizations/accounts';
import { topNavigationI18nStrings } from 'i18n/top-navigation';
import { IdProvider } from 'react-use-id-hook';
import { NotificationProvider } from 'contexts/notification';
import useNotifications from 'hooks/notifications';
import { MidasTablesIndex } from 'pages/midas/tables';
import { MidasQueriesIndex } from 'pages/midas/queries';
import { UpgradeTemplatesIndex } from 'pages/upgrade_templates';

function BaseLayout() {
  const location = useLocation();

  const appLayoutRef = useRef<AppLayoutProps.Ref>(null);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [toolsContent, setToolsContent] = useState<React.ReactNode>(() => (
    <></>
  ));
  const loadHelpPanelContent = (content: React.ReactNode) => {
    setToolsOpen(true);
    setToolsContent(content);
    appLayoutRef.current?.focusToolsClose();
  };

  const {
    splitPanelOpen,
    onSplitPanelToggle,
    splitPanelSize,
    onSplitPanelResize,
    openSplitPanel,
    splitPanelContent,
    setSplitPanelContent,
  } = useSplitPanel();
  const loadSplitPanelContent = (content: React.ReactNode) => {
    setSplitPanelContent(content);
    openSplitPanel();
  };

  useEffect(() => {
    setSplitPanelContent(undefined);
  }, [location]);

  const { notifications, addNotification } = useNotifications();

  applyMode(Mode.Dark);
  applyDensity(Density.Compact);

  const [authDetails, setAuthDetails] = useState<
    GetCallerIdentityCommandOutput | undefined
  >(undefined);
  useEffect(() => {
    const cb = async () => {
      const credentials = await Auth.currentCredentials();
      const stsClient = new STSClient({
        credentials: Auth.essentialCredentials(credentials),
        region: import.meta.env.VITE_REGION,
      });

      const result = await stsClient.send(new GetCallerIdentityCommand({}));
      setAuthDetails(result);
    };
    void cb();
  }, []);

  return (
    <NotificationProvider value={addNotification}>
      <SplitPanelProvider value={loadSplitPanelContent}>
        <HelpPanelProvider value={loadHelpPanelContent}>
          <div style={{ borderBottom: 'solid 1px #414d5c' }}>
            <TopNavigation
              i18nStrings={topNavigationI18nStrings}
              identity={{
                href: '/',
                title: 'Chkk Admin',
              }}
              utilities={[
                {
                  type: 'button',
                  text: `${(authDetails?.Arn || '').split('/')[1]}`,
                  iconName: 'user-profile',
                },
              ]}
            />
          </div>
          <AppLayout
            ref={appLayoutRef}
            contentType="dashboard"
            breadcrumbs={<Breadcrumbs />}
            navigation={<Navigation />}
            ariaLabels={appLayoutAriaLabels}
            notifications={<Flashbar items={notifications} />}
            content={<Outlet />}
            // tools sidebar
            toolsOpen={toolsOpen}
            tools={toolsContent}
            onToolsChange={({ detail }) => setToolsOpen(detail.open)}
            // split panel details
            splitPanelOpen={splitPanelOpen}
            onSplitPanelToggle={onSplitPanelToggle}
            splitPanelSize={splitPanelSize}
            onSplitPanelResize={onSplitPanelResize}
            splitPanel={splitPanelContent}
          />
        </HelpPanelProvider>
      </SplitPanelProvider>
    </NotificationProvider>
  );
}

export function Router() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <IdProvider>
          <ProtectedRoute>
            <BaseLayout />
          </ProtectedRoute>
        </IdProvider>
      ),
      children: [
        {
          element: <BasePage />,
          index: true,
          handle: {
            BreadcrumbLabel: () => 'Dashboard',
          },
        },
        {
          path: 'organizations',
          handle: {
            BreadcrumbLabel: () => 'Organizations',
          },
          children: [
            {
              element: <OrganizationsIndex />,
              index: true,
            },
            {
              path: ':orgId/accounts/:accountId',
              children: [
                {
                  index: true,
                  element: <AccountOverview />,
                  handle: {
                    BreadcrumbLabel: () => 'Account',
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'addons',
          handle: {
            BreadcrumbLabel: () => 'Addons',
          },
          children: [
            {
              element: <AddonsIndex />,
              index: true,
            },
          ],
        },
        {
          path: 'upgrades',
          children: [
            {
              index: true,
              element: <UpgradesIndex />,
            },
          ],
        },
        {
          path: 'upgrade_templates',
          children: [
            {
              index: true,
              element: <UpgradeTemplatesIndex />,
            },
          ],
        },
        {
          path: 'midas',
          children: [
            {
              path: 'tables',
              children: [{ index: true, element: <MidasTablesIndex /> }],
            },
            {
              path: 'queries',
              children: [{ index: true, element: <MidasQueriesIndex /> }],
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
