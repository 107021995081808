import {
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  Spinner,
  SplitPanel,
} from '@cloudscape-design/components';
import { splitPanelI18nStrings } from 'src/i18n/split-panel';

import { useGetAddonInstance, useListAddons } from 'api/admin';

import CopyText from 'components/copy-text';

import { ValueWithLabel } from 'components/value-with-label';
import { format } from 'date-fns';
import { FilterableTable } from 'components/tables/filterable-table';
import { AddonInstanceResource } from 'api/admin-models';

export function AddonInstanceDetails({
  addonInstance: item,
}: {
  addonInstance: { accountId: string; addonInstanceId: string };
}) {
  const { data } = useGetAddonInstance(item.accountId, item.addonInstanceId);
  const { data: addonData } = useListAddons({ page_size: 1000 });

  if (data === undefined) {
    return (
      <SplitPanel
        header={`Addon: ${item.addonInstanceId}`}
        i18nStrings={splitPanelI18nStrings}
      >
        <Spinner />
      </SplitPanel>
    );
  }

  return (
    <SplitPanel
      header={`Addon: ${
        addonData?.data?.find((a) => a.id === data.addon_id)?.name ||
        data.addon_id
      }`}
      i18nStrings={splitPanelI18nStrings}
    >
      <SpaceBetween size="l">
        <Container header={<></>}>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label="Account ID">
                <CopyText
                  copyText={item.accountId}
                  copyButtonLabel="Copy Account ID"
                  successText="Account ID copied"
                  errorText="Account ID failed to copy"
                />
              </ValueWithLabel>
              <ValueWithLabel
                label="Name"
                externalLink={`/addons?select=${data.addon_id}`}
              >
                {addonData?.data?.find((a) => a.id === data.addon_id)?.name ||
                  'n/a'}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Cluster ID">
                <CopyText
                  copyText={data.cluster_id}
                  copyButtonLabel="Copy Cluster ID"
                  successText="Cluster ID copied"
                  errorText="Cluster ID failed to copy"
                />
              </ValueWithLabel>
              <ValueWithLabel label="Version">
                {data.version || 'n/a'}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="ID">
                <CopyText
                  copyText={data.id}
                  copyButtonLabel="Copy Addon Instance ID"
                  successText="Addon Instance ID copied"
                  errorText="Addon Instance ID failed to copy"
                />
              </ValueWithLabel>
              <ValueWithLabel label="EOL">
                {data.eol_date === undefined
                  ? 'n/a'
                  : format(new Date(data.eol_date * 1000), 'Pp')}
              </ValueWithLabel>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
        <Container header={<Header variant="h2">Primary Component</Header>}>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label="Registry URL">
                {data.primary_component?.image?.registry_url || 'n/a'}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Tag">
                {data.primary_component?.image?.tag || 'n/a'}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Digest">
                {data.primary_component?.image?.digest || 'n/a'}
              </ValueWithLabel>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
        <Container header={<Header variant="h2">Resources</Header>}>
          <FilterableTable<AddonInstanceResource>
            selectionMode="none"
            data={data ? data.resources : undefined}
            columns={[
              {
                id: 'namespace',
                label: 'Namespace',
                cell: (item) => item.namespace,

                defaultVisible: true,
                sortingField: 'namespace',
                filterOperators: ['=', '!='],
              },
              {
                id: 'type',
                label: 'Kind',
                cell: (item) => item.type,

                defaultVisible: true,
                sortingField: 'type',
                filterOperators: ['=', '!='],
              },
              {
                id: 'name',
                label: 'Name',
                cell: (item) => item.name,

                defaultVisible: true,
                sortingField: 'name',
                filterOperators: ['=', '!='],
              },
            ]}
            selectionTrackBy={(item) =>
              `${data.id}#${item.type}#${item.name}#${item.namespace}`
            }
          />
        </Container>
      </SpaceBetween>
    </SplitPanel>
  );
}
