import { API, Amplify, Auth } from 'aws-amplify';
import ReactDOM from 'react-dom';

import { Router } from './app';

// ----------------------------------------------------------------------

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: import.meta.env.VITE_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: import.meta.env.VITE_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: import.meta.env.VITE_WEB_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    oauth: {
      domain: import.meta.env.VITE_OAUTH_DOMAIN,
      scope: ['email', 'openid'],
      redirectSignIn: `https://${window.location.host}/`,
      redirectSignOut: `https://${window.location.host}/`,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'admin-api',
        endpoint: import.meta.env.VITE_API_ENDPOINT,
        region: import.meta.env.VITE_REGION,
      },
      {
        name: 'analytics-api',
        endpoint: import.meta.env.VITE_ANALYTICS_API_ENDPOINT,
        region: import.meta.env.VITE_REGION,
      },
    ],
  },
});

ReactDOM.render(<Router />, document.getElementById('root'));
