import { FilterableTable } from 'components/tables/filterable-table';
import { format } from 'date-fns';
import { useListUpgrades } from 'api/admin';
import {
  fromApiResponses,
  UpgradesTableElement,
} from 'pages/upgrade_plans/data-model';
import { UpgradeBadgeStatus } from 'pages/upgrade_plans/helpers';
import { PropertyFilterToken } from '@cloudscape-design/collection-hooks';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { useSplitPanel } from 'contexts/split-panel';
import { UpgradeDetails } from 'pages/upgrade_plans/details';

export function AccountUpgrades(props: {
  orgId: string;
  accountId: string;
}): JSX.Element {
  const updateSplitPanel = useSplitPanel();
  const { data, mutate } = useListUpgrades(props.accountId);

  return (
    <FilterableTable<UpgradesTableElement>
      selectionMode="single"
      data={fromApiResponses(data ? [data] : undefined)}
      columns={[
        {
          id: 'Id',
          label: 'Upgrade ID',
          cell: (item) => item.Id,

          defaultVisible: false,

          sortingField: 'Id',
        },
        {
          id: 'Name',
          label: 'Name',
          cell: (item) => item.Name || '-',

          defaultVisible: true,

          sortingField: 'Name',
          filterOperators: ['=', '!=', ':', '!:'],
        },
        {
          id: 'Status',
          label: 'Status',
          cell: (item) => (
            <UpgradeBadgeStatus value={item.Status || 'pending'} />
          ),

          defaultVisible: true,

          sortingField: 'Status',
          filterOperators: ['=', '!='],
          filterOptions: [
            'pending',
            'in-progress',
            'completed',
            'rolled-back',
            'failed',
          ],
        },
        {
          id: 'CreationStatus',
          label: 'Creation Status',
          cell: (item) => (
            <UpgradeBadgeStatus
              value={item.CreationStatus || 'pending-assignment'}
            />
          ),

          defaultVisible: true,

          sortingField: 'CreationStatus',
          filterOperators: ['=', '!='],
          filterOptions: [
            'pending-assignment',
            'in-progress',
            'in-review',
            'published',
          ],
        },
        {
          id: 'Assignee',
          label: 'Assignee',
          cell: (item) => item.Assignee || '-',
          sortingField: 'Asignee',

          defaultVisible: true,

          filterOperators: ['=', '!='],
        },
        {
          id: 'Creation',
          label: 'Created',
          cell: (item) => format(new Date(item.Created), 'Pp'),

          defaultVisible: true,

          sortingField: 'Created',
        },
      ]}
      defaultFilterQuery={async (searchParams) => {
        const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
        const session = user.getSignInUserSession();

        const tokens: PropertyFilterToken[] = [];

        if (searchParams.get('mine') === 'true') {
          tokens.push({
            propertyKey: 'Assignee',
            value: session?.getIdToken().payload.email as string,
            operator: '=',
          });
        }
        if (searchParams.get('status') === 'pending') {
          tokens.push({
            propertyKey: 'Status',
            value: searchParams.get('status'),
            operator: '=',
          });
        }
        return { tokens: tokens, operation: 'and' };
      }}
      selectionTrackBy={(item) => `${item.AccountId}#${item.Id}`}
      onSelectionChange={(event) => {
        updateSplitPanel(
          <UpgradeDetails
            upgrade={{
              accountId: event.detail.selectedItems[0].AccountId,
              upgradeId: event.detail.selectedItems[0].Id,
            }}
            onChange={() => void mutate()}
          />,
        );
      }}
    />
  );
}
