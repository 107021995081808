import { CodeEditor } from '@cloudscape-design/components';
import * as ace from 'ace-builds';
import 'ace-builds/css/ace.css';
import 'ace-builds/src-noconflict/ace';
import extLanguageToolsUrl from 'ace-builds/src-noconflict/ext-language_tools?url';
import sqlModeUrl from 'ace-builds/src-noconflict/mode-sql?url';
import monokaiThemeUrl from 'ace-builds/src-noconflict/theme-monokai?url';
import tomorrowNightBrighThemeUrl from 'ace-builds/src-noconflict/theme-tomorrow_night_bright?url';
import { LanguageProvider } from 'ace-linters';
import { useEffect, useState } from 'react';
import { codeEditorAriaLabels } from 'src/i18n/code-editor';

export interface QueryEditorProps {
  readonly loading: boolean;
  readonly code: string;

  readonly onDelayedChange: (newCode: string) => void;
}

export function QueryEditor(props: QueryEditorProps): JSX.Element {
  const [preferences, setPreferences] = useState({});

  useEffect(() => {
    ace.config.setModuleUrl('ace/mode/sql', sqlModeUrl);
    ace.config.setModuleUrl('ace/theme/monokai', monokaiThemeUrl);
    ace.config.setModuleUrl(
      'ace/theme/tomorrow_night_bright',
      tomorrowNightBrighThemeUrl,
    );
    ace.config.setModuleUrl('ace/ext/language_tools', extLanguageToolsUrl);

    setTimeout(() => {
      const worker = new Worker(
        new URL('./editor.worker.ts', import.meta.url),
        { type: 'module' },
      );
      worker.postMessage({});

      const editor = ace.edit(document.querySelector('.ace_editor') || '');
      editor.setOptions({
        behavioursEnabled: false,
        wrapBehavioursEnabled: false,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
      });
      ace.require('ace/ext/language_tools');

      const languageProvider = LanguageProvider.create(worker);
      languageProvider.registerEditor(editor);
      languageProvider.setSessionOptions(editor.session, {});
    }, 1000);
  }, []);

  return (
    <CodeEditor
      controlId="code-editor"
      ace={ace}
      value={props.code}
      onDelayedChange={(event) => props.onDelayedChange(event.detail.value)}
      language="sql"
      preferences={preferences}
      onPreferencesChange={(event) => setPreferences(event.detail)}
      loading={props.loading}
      i18nStrings={codeEditorAriaLabels}
      themes={{ light: [], dark: ['monokai', 'tomorrow_night_bright'] }}
    />
  );
}
