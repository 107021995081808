/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Chkk Analytics API v1.0
 * Chkk Analytics API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */
import useSwr from 'swr';
import type { SWRConfiguration, Key } from 'swr';
import type {
  ListTablesResponse,
  ListTablesParams,
  Table,
  ListQueriesResponse,
  ListQueriesParams,
  CreateQueryRequest,
  Query,
  UpdateQueryRequest,
  ListQueryExecutionsResponse,
  ListQueryExecutionsParams,
  QueryExecution,
  CreateQueryExecutionRequest,
} from './analytics-models';
import { customAnalyticsApiInstance } from './custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List tables
 */
export const listTables = (params?: ListTablesParams) => {
  return customAnalyticsApiInstance<ListTablesResponse>({
    url: `/tables`,
    method: 'get',
    params,
  });
};

export const getListTablesKey = (params?: ListTablesParams) =>
  [`/tables`, ...(params ? [params] : [])] as const;

export type ListTablesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listTables>>
>;
export type ListTablesQueryError = unknown;

export const useListTables = <TError = unknown>(
  params?: ListTablesParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listTables>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getListTablesKey(params) : null));
  const swrFn = () => listTables(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get details of a specific table
 */
export const getTable = (tableName: string) => {
  return customAnalyticsApiInstance<Table>({
    url: `/tables/${tableName}`,
    method: 'get',
  });
};

export const getGetTableKey = (tableName: string) =>
  [`/tables/${tableName}`] as const;

export type GetTableQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTable>>
>;
export type GetTableQueryError = unknown;

export const useGetTable = <TError = unknown>(
  tableName: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getTable>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!tableName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetTableKey(tableName) : null));
  const swrFn = () => getTable(tableName);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List queries
 */
export const listQueries = (params?: ListQueriesParams) => {
  return customAnalyticsApiInstance<ListQueriesResponse>({
    url: `/queries`,
    method: 'get',
    params,
  });
};

export const getListQueriesKey = (params?: ListQueriesParams) =>
  [`/queries`, ...(params ? [params] : [])] as const;

export type ListQueriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listQueries>>
>;
export type ListQueriesQueryError = unknown;

export const useListQueries = <TError = unknown>(
  params?: ListQueriesParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listQueries>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListQueriesKey(params) : null));
  const swrFn = () => listQueries(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Create a query
 */
export const createQuery = (createQueryRequest: CreateQueryRequest) => {
  return customAnalyticsApiInstance<void>({
    url: `/queries`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createQueryRequest,
  });
};

/**
 * Get details of a specific query
 */
export const getQuery = (queryId: string) => {
  return customAnalyticsApiInstance<Query>({
    url: `/queries/${queryId}`,
    method: 'get',
  });
};

export const getGetQueryKey = (queryId: string) =>
  [`/queries/${queryId}`] as const;

export type GetQueryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuery>>
>;
export type GetQueryQueryError = unknown;

export const useGetQuery = <TError = unknown>(
  queryId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getQuery>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!queryId;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetQueryKey(queryId) : null));
  const swrFn = () => getQuery(queryId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Update a query
 */
export const updateQuery = (
  queryId: string,
  updateQueryRequest: UpdateQueryRequest,
) => {
  return customAnalyticsApiInstance<void>({
    url: `/queries/${queryId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateQueryRequest,
  });
};

/**
 * List query executions
 */
export const listQueryExecutions = (
  queryId: string,
  params?: ListQueryExecutionsParams,
) => {
  return customAnalyticsApiInstance<ListQueryExecutionsResponse>({
    url: `/queries/${queryId}/executions`,
    method: 'get',
    params,
  });
};

export const getListQueryExecutionsKey = (
  queryId: string,
  params?: ListQueryExecutionsParams,
) => [`/queries/${queryId}/executions`, ...(params ? [params] : [])] as const;

export type ListQueryExecutionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listQueryExecutions>>
>;
export type ListQueryExecutionsQueryError = unknown;

export const useListQueryExecutions = <TError = unknown>(
  queryId: string,
  params?: ListQueryExecutionsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listQueryExecutions>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!queryId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListQueryExecutionsKey(queryId, params) : null));
  const swrFn = () => listQueryExecutions(queryId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Create a query execution
 */
export const createQueryExecution = (
  queryId: string,
  createQueryExecutionRequest: CreateQueryExecutionRequest,
) => {
  return customAnalyticsApiInstance<QueryExecution>({
    url: `/queries/${queryId}/executions`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createQueryExecutionRequest,
  });
};

/**
 * Get details of a specific query execution
 */
export const getQueryExecution = (queryId: string, executionId: string) => {
  return customAnalyticsApiInstance<QueryExecution>({
    url: `/queries/${queryId}/executions/${executionId}`,
    method: 'get',
  });
};

export const getGetQueryExecutionKey = (queryId: string, executionId: string) =>
  [`/queries/${queryId}/executions/${executionId}`] as const;

export type GetQueryExecutionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQueryExecution>>
>;
export type GetQueryExecutionQueryError = unknown;

export const useGetQueryExecution = <TError = unknown>(
  queryId: string,
  executionId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getQueryExecution>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(queryId && executionId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetQueryExecutionKey(queryId, executionId) : null));
  const swrFn = () => getQueryExecution(queryId, executionId);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};
