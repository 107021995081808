import { UpgradeTemplatesTableElement, fromApiResponses } from './data-model';
import { UpgradeTemplateDetails } from './details';
import { UpgradeBadgeStatus } from './helpers';
import { PropertyFilterToken } from '@cloudscape-design/collection-hooks';
import { Button, ContentLayout, Header } from '@cloudscape-design/components';
import { useGlobalListUpgradeTemplatesInfinite } from 'api/admin-infinite';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { FilterableTable } from 'components/tables/filterable-table';
import { useSplitPanel } from 'contexts/split-panel';
import { format } from 'date-fns';

export function UpgradeTemplatesIndex(): JSX.Element {
  const updateSplitPanel = useSplitPanel();
  const { data, mutate } = useGlobalListUpgradeTemplatesInfinite();

  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          actions={
            <Button
              iconName="refresh"
              variant="icon"
              onClick={() => void mutate()}
            />
          }
        >
          Upgrade Templates
        </Header>
      }
    >
      <FilterableTable<UpgradeTemplatesTableElement>
        variant={'container'}
        selectionMode="single"
        data={fromApiResponses(data)}
        columns={[
          {
            id: 'AccountId',
            label: 'Account ID',
            cell: (item) => item.AccountId,

            defaultVisible: true,
            alwaysVisible: true,

            sortingField: 'AccountId',
          },
          {
            id: 'Name',
            label: 'Name',
            cell: (item) => item.Name || '-',

            defaultVisible: true,

            sortingField: 'Name',
            filterOperators: ['=', '!=', ':', '!:'],
          },
          {
            id: 'Environment',
            label: 'Environment',
            cell: (item) => item.Environment || '-',

            defaultVisible: true,

            sortingField: 'Environment',
            filterOperators: ['=', '!=', ':', '!:'],
          },
          {
            id: 'Status',
            label: 'Status',
            cell: (item) => (
              <UpgradeBadgeStatus value={item.Status || 'pending'} />
            ),

            defaultVisible: true,

            sortingField: 'Status',
            filterOperators: ['=', '!='],
            filterOptions: [
              'pending',
              'in-progress',
              'completed',
              'rolled-back',
              'failed',
            ],
          },
          {
            id: 'CreationStatus',
            label: 'Creation Status',
            cell: (item) => (
              <UpgradeBadgeStatus
                value={item.CreationStatus || 'pending-assignment'}
              />
            ),

            defaultVisible: true,

            sortingField: 'CreationStatus',
            filterOperators: ['=', '!='],
            filterOptions: [
              'pending-assignment',
              'in-progress',
              'in-review',
              'published',
            ],
          },
          {
            id: 'Assignee',
            label: 'Assignee',
            cell: (item) => item.Assignee || '-',
            sortingField: 'Assignee',

            defaultVisible: true,

            filterOperators: ['=', '!='],
          },
          {
            id: 'Creation',
            label: 'Created',
            cell: (item) => format(new Date(item.Created), 'Pp'),

            defaultVisible: true,

            sortingField: 'Created',
          },
        ]}
        defaultFilterQuery={async (searchParams) => {
          const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
          const session = user.getSignInUserSession();
          const tokens: PropertyFilterToken[] = [];

          if (searchParams.get('mine') === 'true') {
            tokens.push({
              propertyKey: 'Assignee',
              value: session?.getIdToken().payload.email as string,
              operator: '=',
            });
          }
          if (searchParams.get('status') === 'pending') {
            tokens.push({
              propertyKey: 'Status',
              value: searchParams.get('status'),
              operator: '=',
            });
          }
          return { tokens: tokens, operation: 'and' };
        }}
        selectionTrackBy={(item) => `${item.AccountId}#${item.Id}`}
        onSelectionChange={(event) => {
          updateSplitPanel(
            <UpgradeTemplateDetails
              upgrade={{
                accountId: event.detail.selectedItems[0].AccountId,
                upgradeId: event.detail.selectedItems[0].Id,
              }}
              onChange={() => void mutate()}
            />,
          );
        }}
        header={<Header />}
      />
    </ContentLayout>
  );
}
