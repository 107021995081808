import {
  useListAddonInstances,
  useListAddons,
  useListClusters,
} from 'api/admin';
import { AddonInstance } from 'api/admin-models';
import { FilterableTable } from 'components/tables/filterable-table';
import { format } from 'date-fns';
import { useSplitPanel } from 'contexts/split-panel';
import { AddonInstanceDetails } from './addons_details';

interface TableData extends AddonInstance {
  cluster_name?: string;
  addon_name?: string;
  primary_component_name?: string;
}

export function AccountAddonInstances(props: {
  orgId: string;
  accountId: string;
}): JSX.Element {
  const updateSplitPanel = useSplitPanel();
  const { data } = useListAddonInstances(props.accountId, { page_size: 1000 });
  const { data: clusterData } = useListClusters(props.accountId);
  const { data: addonData } = useListAddons({ page_size: 1000 });

  const tableData: TableData[] | undefined = data?.data?.map((item) => ({
    cluster_name: clusterData?.data?.find((c) => c.id === item.cluster_id)
      ?.name,
    addon_name: addonData?.data?.find((c) => c.id === item.addon_id)?.name,
    primary_component_name: item.primary_component?.image?.registry_url,

    ...item,
  }));

  return (
    <FilterableTable<TableData>
      selectionMode="single"
      data={tableData ? tableData : undefined}
      columns={[
        {
          id: 'cluster_name',
          label: 'Cluster',
          cell: (item) => item.cluster_name || item.cluster_id || '-',

          defaultVisible: true,
          sortingField: 'cluster_name',
          filterOperators: ['=', '!=', ':', '!:'],
          filterOptions: Array.from(
            new Set(
              tableData
                ?.filter((c) => c.cluster_name)
                .map((c) => c.cluster_name || ''),
            ),
          ),
        },
        {
          id: 'addon_name',
          label: 'Name',
          cell: (item) => item.addon_name || item.addon_id || '-',

          defaultVisible: true,

          sortingField: 'addon_name',
          filterOperators: ['=', '!=', ':', '!:'],
          filterOptions: Array.from(
            new Set(
              tableData
                ?.filter((c) => c.addon_name)
                .map((c) => c.addon_name || ''),
            ),
          ),
        },
        {
          id: 'version',
          label: 'Version',
          cell: (item) => item.version || '-',

          defaultVisible: true,

          sortingField: 'version',
          filterOperators: ['=', '!=', ':', '!:'],
        },
        {
          id: 'eol_date',
          label: 'EOL',
          cell: (item) =>
            item.eol_date === undefined
              ? '-'
              : format(new Date(item.eol_date * 1000), 'Pp'),

          defaultVisible: true,

          sortingField: 'eol_date',
          filterOperators: ['=', '!=', '>', '<', '>=', '<='],
        },
        {
          id: 'primary_component_name',
          label: 'Primary Component',
          cell: (item) => item.primary_component_name || '-',

          defaultVisible: true,

          sortingField: 'primary_component_name',
          filterOperators: ['=', '!=', ':', '!:'],
        },
      ]}
      selectionTrackBy={(item) => `${item.cluster_id}#${item.id}`}
      onSelectionChange={(event) => {
        updateSplitPanel(
          <AddonInstanceDetails
            addonInstance={{
              accountId: props.accountId,
              addonInstanceId: event.detail.selectedItems[0].id,
            }}
          />,
        );
      }}
    />
  );
}
