import {
  Box,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  Spinner,
  SplitPanel,
} from '@cloudscape-design/components';
import { splitPanelI18nStrings } from 'src/i18n/split-panel';

import { useGetAddon } from 'api/admin';

const ValueWithLabel = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

export function AddonDetails({
  addonId,
  addonName,
}: {
  addonId: string;
  addonName: string;
}) {
  const { data: addonDetails, isLoading: isLoadingOrgDetails } = useGetAddon(
    addonId || '',
  );

  if (isLoadingOrgDetails || addonDetails === undefined) {
    return (
      <SplitPanel
        header={`Addon: ${addonName}`}
        i18nStrings={splitPanelI18nStrings}
      >
        <Spinner /> Still Loading
      </SplitPanel>
    );
  }
  return (
    <SplitPanel
      header={`Addon: ${addonDetails.name}`}
      i18nStrings={splitPanelI18nStrings}
    >
      <SpaceBetween size="l">
        <Container header={<Header variant="h2">Addon details </Header>}>
          <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="l">
              <ValueWithLabel label="Name">{addonDetails.name}</ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Website">
                <Link external href={addonDetails.website}>
                  {addonDetails.website}
                </Link>
              </ValueWithLabel>
              <ValueWithLabel label="Managed Cloud Providers">
                {(addonDetails.managed_addon_providers || [])
                  .map((m) => m.name)
                  .join(', ')}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Licenses">
                {(addonDetails.licenses || []).map((l) => l.name).join(', ')}
              </ValueWithLabel>
              <ValueWithLabel label="Source Repos">
                {(addonDetails.source_code || []).map((sc) => (
                  <Link external href={sc.url} key={sc.url}>
                    {sc.hosting_provider}
                  </Link>
                ))}
              </ValueWithLabel>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
        <Container header={<Header variant="h2">Helm Charts</Header>}>
          <ul>
            {(addonDetails.helm_charts || []).map((hc) => (
              <li key={hc.url}>
                <Link external href={hc.url}>
                  {hc.url}
                </Link>
                : {hc.description}
              </li>
            ))}
          </ul>
        </Container>
        <Container header={<Header variant="h2">Public Registries</Header>}>
          <ul>
            {(addonDetails.public_registries || []).map((registry) => (
              <li key={registry.url}>
                <Link external href={registry.url}>
                  {registry.url}
                </Link>
                : {registry.description}
              </li>
            ))}
          </ul>
        </Container>
      </SpaceBetween>
    </SplitPanel>
  );
}
