import {
  Button,
  ButtonProps,
  StatusIndicator,
} from '@cloudscape-design/components';
import { useState } from 'react';

import {
  UpgradeTemplateCreationStatus,
  UpgradeTemplateStatus,
} from 'api/admin-models';

export function UpgradeBadgeStatus({
  value,
}: {
  value: UpgradeTemplateStatus | UpgradeTemplateCreationStatus;
}): JSX.Element {
  if (value === 'pending' || value === 'pending-assignment') {
    return <StatusIndicator type="warning">{value}</StatusIndicator>;
  } else if (value === 'in-progress') {
    return <StatusIndicator type="in-progress">{value}</StatusIndicator>;
  } else if (value === 'in-review') {
    return <StatusIndicator type="info">{value}</StatusIndicator>;
  } else if (value === 'published') {
    return <StatusIndicator type="success">{value}</StatusIndicator>;
  } else if (value == 'cancelled') {
    return <StatusIndicator type="stopped">{value}</StatusIndicator>;
  } else if (value == 'completed') {
    return <StatusIndicator type="success">{value}</StatusIndicator>;
  } else if (value == 'outdated') {
    return <StatusIndicator type="warning">{value}</StatusIndicator>;
  } else if (value == 'update-in-progress') {
    return <StatusIndicator type="in-progress">{value}</StatusIndicator>;
  } else if (value == 'regenerate-in-progress') {
    return <StatusIndicator type="pending">regenerate-requested</StatusIndicator>;
  } else {
    return <>{value}</>;
  }
}

export function TemporalBadgeStatus({
  value,
}: {
  value?: string;
}): JSX.Element {
  if (value === undefined || value === '') {
    return <StatusIndicator type="pending">Not created</StatusIndicator>;
  } else if (value === 'Terminated') {
    return <StatusIndicator type="error">{value}</StatusIndicator>;
  } else {
    return <StatusIndicator type="info">{value}</StatusIndicator>;
  }
}

export interface ActionButtonProps extends ButtonProps {
  onConfirm?: () => Promise<void>;
}

export function ActionButton({
  onConfirm,
  ...otherProps
}: ActionButtonProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    try {
      if (onConfirm !== undefined) {
        await onConfirm();
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button
      loading={loading}
      onClick={() => void handleClick()}
      {...otherProps}
    />
  );
}
