/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Chkk Admin API v1.0
 * Chkk Admin API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

/**
 * The type of Cloud Connection
 */
export type CloudType = (typeof CloudType)[keyof typeof CloudType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CloudType = {
  AWS: 'AWS',
  GCP: 'GCP',
  AZURE: 'AZURE',
  OCI: 'OCI',
} as const;
