import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export function ProtectedRoute(props: { children: JSX.Element }): JSX.Element {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [searchParams] = useSearchParams();
  const checkUserToken = () => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch(() => {
        if (searchParams.has('code')) {
          // we already did that, so no need to kick of sign in again
          return;
        }

        setIsLoggedIn(true);
        void Auth.federatedSignIn({
          customProvider: 'GoogleWorkspace',
        });
      });
  };
  useEffect(() => {
    if (!isLoggedIn) {
      checkUserToken();
    }
  }, []);

  return <>{isLoggedIn ? props.children : null}</>;
}
